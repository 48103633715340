import colors from "colors";
import { IconProps } from "./types";

export default function IcoGrabber({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M4 9L20 9M4 15L20 15" stroke={color} strokeLinecap="round" />
		</svg>
	);
}
