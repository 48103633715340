import { IcoError, IcoSuccess, IcoWarning } from "assets/icons";
import "./styles.scss";
import { ModalProps } from "./types";

export default function Modal({
	title,
	isOpen,
	children,
	onClose = () => {},
	cssClass,
	kind = "default"
}: ModalProps) {
	const classNames = `modal ${isOpen ? "open" : ""} ${cssClass || ""}`;

	const getIcon = () => {
		switch (kind) {
			case "warning":
				return <IcoWarning />;
			case "error":
				return <IcoError />;
			case "success":
				return <IcoSuccess />;
			default:
				return null;
		}
	};

	return (
		<div className={`modal-wrapper ${isOpen ? "open" : ""}`} onClick={onClose}>
			<div className={classNames} onClick={(e) => e.stopPropagation()}>
				<span className="modal-icon">{getIcon()}</span>
				<span className="modal-title">{title}</span>
				<div className="content">{children}</div>
			</div>
		</div>
	);
}
