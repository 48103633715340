function bool(name: string) {
	return process.env[name]?.toLocaleLowerCase() === "true";
}

const baseConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	imageURL: process.env.REACT_APP_IMAGE_SOURCE_URL,
	mockRequest: bool("REACT_APP_API_MOCK"),
	mockAuth: bool("REACT_APP_API_AUTH_MOCK"),
	mockUserRole: (process.env.REACT_APP_MOCK_USER_ROLE ?? "ADMIN").toUpperCase()
};

export { baseConfig };
