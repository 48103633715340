export default function IcoWarning() {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
				fill="#F9C006"
			/>
			<path
				d="M20 22L20 14"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="20"
				cy="25"
				r="1"
				transform="rotate(-180 20 25)"
				fill="white"
			/>
		</svg>
	);
}
