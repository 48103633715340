import { SortingState } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { IcoExport } from "assets/icons";
import {
	Button,
	Container,
	EmptyList as EmptyTasksList,
	Loading
} from "components";
import { FileHelper } from "helpers";
import TableHelper from "helpers/TableHelper";
import { TaskService } from "services/tasks";

import colors from "colors";
import {
	useQuerystringFilters,
	useQuerystringParams
} from "hooks/querystringFilter";

import ToastContent from "components/ToastContent";
import { useHandleBack } from "hooks/useHandleBack";
import { useNavigate } from "react-router-dom";
import TasksTable from "./TasksTable";
import { TaskFilterTable } from "./types";

import StatusFilterHeaders from "./StatusFilterHeaders";
import "./styles.scss";
import TasksListFilters from "./TasksListFilters";

export default function TaskList() {
	const taskService = new TaskService();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [tasks, setTasks] = useState<any>([]);
	const [openTasksCount, setOpenTasksCount] = useState<any>([]);
	const [overdueTasksCount, setOverdueTasksCount] = useState<any>([]);
	const [completedTasksCount, setCompletedTasksCount] = useState<any>([]);
	const [siacorpTasksCount, setSiacorpTasksCount] = useState<any>([]);

	const [filters, setFilters] = useState<TaskFilterTable>({
		size: 10,
		page: 1
	});
	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<TaskFilterTable>(
		setFilters,
		sorting,
		setSorting
	);
	const { getUrlFilters } = useQuerystringFilters(filters);

	const [statusFilter, setStatusFilter] = useState<string>("open");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// FIXME: temporarily enabling ADMIN user to see the same monitoring screen than ANALYST
	// in the future, ADMIN should have a proper monitoring dashboard customized for their experience

	const parseParams = (status?: number) => {
		let sortParam;
		if (statusFilter === "open" || statusFilter === "overdue") {
			sortParam = TableHelper.sortingStateToParam(sorting, "dueDate,asc");
		} else {
			sortParam = TableHelper.sortingStateToParam(sorting, "id,desc");
		}
		const filtersParams = {
			...filters,
			status,
			teamsIds: filters.teams,
			categoriesIds: filters.categories,
			covenantsIds: filters.covenants
		};
		if ("teams" in filtersParams) {
			delete filtersParams.teams;
		}
		if ("categories" in filtersParams) {
			delete filtersParams.categories;
		}
		if ("covenants" in filtersParams) {
			delete filtersParams.covenants;
		}
		return {
			...sortParam,
			...filtersParams
		};
	};

	const getOpenTasks = () => {
		if (statusFilter === "open") {
			setIsFetchingData(true);
		}
		taskService
			.listTasksPaginated(parseParams(1))
			.then((response) => {
				setOpenTasksCount(response.totalElements);
				if (statusFilter === "open") {
					setTotalPages(response.totalPages);
					setTasks(response.content);
					setIsFetchingData(false);
				}
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const getOverdueTasks = () => {
		if (statusFilter === "overdue") {
			setIsFetchingData(true);
		}
		taskService
			.listTasksPaginated(parseParams(2))
			.then((response) => {
				setOverdueTasksCount(response.totalElements);
				if (statusFilter === "overdue") {
					setTotalPages(response.totalPages);
					setTasks(response.content);
					setIsFetchingData(false);
				}
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const getCompletedTasks = () => {
		if (statusFilter === "completed") {
			setIsFetchingData(true);
		}
		taskService
			.listTasksPaginated(parseParams(3))
			.then((response) => {
				setCompletedTasksCount(response.totalElements);
				if (statusFilter === "completed") {
					setTotalPages(response.totalPages);
					setTasks(response.content);
					setIsFetchingData(false);
				}
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const getSiacorpTasks = () => {
		if (statusFilter === "siacorp") {
			setIsFetchingData(true);
		}
		taskService
			.listTasksPaginated(parseParams(4))
			.then((response) => {
				setSiacorpTasksCount(response.totalElements);
				if (statusFilter === "siacorp") {
					setTotalPages(response.totalPages);
					setTasks(response.content);
					setIsFetchingData(false);
				}
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const getTasks = () => {
		switch (statusFilter) {
			case "open":
				getOpenTasks();
				break;
			case "overdue":
				getOverdueTasks();
				break;
			case "completed":
				getCompletedTasks();

				break;
			case "siacorp":
				getSiacorpTasks();

				break;
			default:
				getOpenTasks();
		}
	};

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;
		const newFilters = {
			size: filters.size,
			page: 1,
			...appliedFilters,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};
		updateUrl(newFilters);
	};

	const downloadReport = () => {
		setLoading(true);

		taskService
			.exportTasks(parseParams())
			.then((response) => {
				setLoading(false);

				toast.dark(
					<ToastContent
						type="success"
						title="Dowload concluído"
						subtitle="O relatório com os itens selecionados foi gerado com sucesso"
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				FileHelper.downloadExcelFile(response, "covenants_audit.xlsx");
			})
			.catch((error) => {
				setLoading(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const onChangeStatusFilter = () => {
		filters.page = 1;
		const newFilters = { ...filters };
		updateUrl(newFilters);
	};

	useEffect(() => {
		const delay = 200;
		const timer = setTimeout(() => {
			if (!initialized) {
				onApplyFilters(getUrlFilters());
				setInitialized(true);
			} else {
				getOpenTasks();
				getOverdueTasks();
				getCompletedTasks();
				getSiacorpTasks();
			}
		}, delay);

		return () => clearTimeout(timer);
	}, [filters]);

	useEffect(() => {
		setIsFetchingData(true);
	}, [filters]);

	useHandleBack(navigate);

	return (
		<Container className="tasks-page">
			<div className="tasks-heading">
				<span className="tasks-title">Monitoramento</span>

				<TasksListFilters
					onApply={onApplyFilters}
					statusFilter={statusFilter}
				/>
				<Button
					kind="default"
					styled="primary"
					cssClass={
						loading
							? "download-audit-report-button export-btn-loader"
							: "download-audit-report-button"
					}
					onClick={downloadReport}
				>
					<IcoExport color={colors.neutral["high-pure-50"]} />
					Exportar relatório
				</Button>
			</div>
			<StatusFilterHeaders
				onFilterChange={setStatusFilter}
				onPageChange={onChangeStatusFilter}
				openTasksCount={openTasksCount}
				overdueTasksCount={overdueTasksCount}
				completedTasksCount={completedTasksCount}
				siacorpTasksCount={siacorpTasksCount}
			/>
			{isFetchingData ? (
				<Loading type="primary" />
			) : tasks.length === 0 ? (
				<EmptyTasksList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<TasksTable
					content={tasks}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
					reloadData={getTasks}
					statusFilter={statusFilter}
				/>
			)}
		</Container>
	);
}
