import { useState } from "react";

import { Select } from "components";

import { TaskStatusOptions } from "models/covenant/enums";
import { TaskService } from "services/tasks";

import { ClauseBatchActionDrawer } from "features";
import "./styles.scss";

interface ClauseIds {
	[key: string]: string;
}
type CompleteTaskDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	taskIds: string[];
	clauseIds: ClauseIds;
	reloadTable: () => void;
};

export default function CompleteTaskDrawer({
	isOpen,
	onClose,
	taskIds,
	clauseIds,
	reloadTable
}: CompleteTaskDrawerProps) {
	const taskService = new TaskService();
	const [statusSelected, setStatusSelected] = useState<number>(1);

	const completeTask = async () => {
		await taskService.completeBatch({
			taskIds,
			clauseIds,
			status: statusSelected
		});

		onClose();
		reloadTable();
	};

	return (
		<ClauseBatchActionDrawer
			isOpen={isOpen}
			onClose={onClose}
			clausesQty={taskIds.length}
			value={statusSelected}
			setValue={setStatusSelected}
			title="Concluir cláusulas"
			alertTitle="Atenção"
			alertMessage="Com o objetivo de evitar problemas e impactos negativos no contrato, é importante que você leia com atenção cada uma das tarefas selecionadas antes de concluí-las."
			alertType="warning"
			onConfirm={completeTask}
			countTitle="Tarefas selecionadas"
			modalTitle="Concluir tarefas"
			modalInfo="Ao confirmar, todas as tarefas selecionadas serão concluídas e não poderão ser alteradas."
			toastSuccessTitle="Tarefas concluídas"
			toastSuccessMessage="As tarefas selecionadas foram concluídas"
			kind="success"
		>
			<div>Status da cláusula</div>
			<div className="status-selector">
				<Select
					label=""
					options={TaskStatusOptions}
					onChange={(selected: any) => setStatusSelected(selected.value)}
					value={TaskStatusOptions.find(
						(type) => type.value === statusSelected
					)}
					placeholder="Selecione"
				/>
			</div>
		</ClauseBatchActionDrawer>
	);
}
