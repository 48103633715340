import { IcoSignalError } from "assets/icons";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { clear } from "store/features/covenant/slice";
import "./styles.scss";

export default function ImportError() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { importErrors } = useSelector((state: RootState) => state.covenant);
	const fileErrors: any = importErrors?.["Arquivo do Contrato"];
	const countErrors =
		typeof fileErrors?.[0].details === "object"
			? fileErrors?.[0].details?.length
			: fileErrors?.length;

	return (
		<div className="covenant-import-error-page stack--4">
			<div className="svg-error-modal">
				<IcoSignalError />
			</div>
			<div className="stack--3">
				<div className="title">Erro ao importar</div>
				<div className="description">
					Foram encontrados {countErrors} erro{countErrors > 1 ? "s" : ""} no
					arquivo, e não foi possível concluir o importe, revise os erros e
					tente novamente
				</div>
			</div>
			<div className="stack--3">
				<Button
					kind="default"
					styled="primary"
					onClick={() => navigate("/covenants/create/error/details")}
				>
					Exibir erros
				</Button>
				<Button
					kind="default"
					styled="secondary"
					onClick={() => {
						dispatch(clear());
						navigate("/covenants/create");
					}}
				>
					Voltar ao início
				</Button>
			</div>
		</div>
	);
}
