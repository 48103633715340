import { CodeName } from "models/types";
import { BaseModel } from "../BaseModel";
import { MonitoringProps } from "./types";

export class Monitoring extends BaseModel {
	id: number;
	name: string;
	frequency: CodeName;
	monitoringLevel: CodeName;
	keyword?: string;

	constructor(data: MonitoringProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.frequency = data.frequency;
		this.monitoringLevel = data.monitoringLevel;
		this.keyword = data.keyword;
	}
}
