import { IconProps } from "./types";

export default function IcoTrash({
	color = "#F83446",
	size = "1.5rem"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="-2 -2 37 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.1196 28H9.41372L8.00195 8.5H23.5314L22.1196 28Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.998 8.5V6C10.998 4.89543 11.8935 4 12.998 4H15.9392H18.8804C19.985 4 20.8804 4.89543 20.8804 6V8.5"
				stroke={color}
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path
				d="M4 8.5H28"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
