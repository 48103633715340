import { IconProps } from "./types";

export default function IcoPencil({
	color = "#0D0D0D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="-5 -5 37 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 23L23.6116 6.50255C24.3832 5.7362 24.4011 4.4937 23.6518 3.70549L23.0399 3.06184L22.49 2.48339C21.7165 1.66973 20.425 1.65197 19.6294 2.44404L3 19L1 25L7 23Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
