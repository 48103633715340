import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Covenant } from "models/covenant/Covenant";

export interface ImportErrorItem {
	line: number;
	type: string;
	field: string;
	error: string;
}

export interface ImportErrorDetails {
	details: ImportErrorItem[];
	message: string;
}

export interface ImportErrorDetailsMissingFields {
	details: {
		[key: string]: string[];
	};
}

export interface ImportErrors {
	"Arquivo do Contrato"?: ImportErrorDetails[];
	timestamp: string;
	message: string;
	code: string;
	errors: ImportErrorItem[];
}

export interface CovenantState {
	importErrors?: ImportErrors;
	covenantCreated?: Covenant;
	covenantEdited?: Covenant;
}

const initialState = {
	importErrors: undefined,
	covenantCreated: undefined,
	covenantEdited: undefined
} as CovenantState;

export const covenantSlice = createSlice({
	name: "covenant",
	initialState,
	reducers: {
		setImportErrors: (state, action: PayloadAction<ImportErrors>) => {
			state.importErrors = action.payload;
		},
		setCovenantCreated: (state, action: PayloadAction<Covenant>) => {
			state.covenantCreated = action.payload;
		},
		setCovenantEdited: (state, action: PayloadAction<Covenant>) => {
			state.covenantEdited = action.payload;
		},
		clear: () => initialState
	}
});

export const { setImportErrors, setCovenantCreated, setCovenantEdited, clear } =
	covenantSlice.actions;
export default covenantSlice.reducer;
