import { Button, Drawer } from "components";

import { IcoArrowRight } from "assets/icons";
import colors from "colors";
import { Team } from "models/auth/Team";
import { User } from "models/auth/User";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

type TeamDetailsDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	team: Team;
};

export default function TeamDetailsDrawer({
	isOpen,
	onClose,
	team
}: TeamDetailsDrawerProps) {
	const navigate = useNavigate();

	return (
		<Drawer
			isOpen={isOpen}
			onClose={onClose}
			title="Detalhes"
			cssClass="team-details-drawer-component"
		>
			<div className="team-details-drawer">
				<p className="info-title">Informações do time</p>
				<div className="team-info-wrapper">
					<div className="team-info-row">
						<span className="team-info-label">Time</span>
						<span className="team-info-value">{team?.name}</span>
					</div>
					<div className="team-info-row">
						<span className="team-info-label">Criado em</span>
						<span className="team-info-value" id="team-created-on">
							{moment
								.tz(team?.createdOn, "America/Sao_Paulo")
								.format("DD/MM/YYYY HH:mm")}
						</span>
					</div>
				</div>
				<p className="info-title">Usuários</p>
				{team?.users?.length > 0 ? (
					<div className="associated-user-wrapper">
						{team?.users?.map((user: User) => (
							<div className="user-info-row" key={`team-user-info-${user.id}`}>
								<span className="user-info-label">{user.name}</span>
								<span className="user-info-value">{user.role}</span>
							</div>
						))}
					</div>
				) : (
					<div className="associated-user-wrapper">
						<div className="user-info-row">
							<span className="user-info-label">Sem usuários associados</span>
						</div>
					</div>
				)}
				<p className="info-title">Cláusulas</p>
				<div className="clauses-row">
					<div className="clauses-info-label">{team?.clauseCountLabel}</div>
					{team?.clauseCountLabel !== "Sem cláusulas associadas" && (
						<span>
							<Button
								cssClass="right-arrow"
								kind="icon"
								styled="ghost"
								type="button"
								onClick={() => navigate(`/clauses?teams=${team?.id}`)}
							>
								<IcoArrowRight color={colors.neutral["low-pure-500"]} />
							</Button>
						</span>
					)}
				</div>
			</div>
		</Drawer>
	);
}
