import { toast } from "react-toastify";

import { Button } from "components";
import Modal from "components/Modal";

import ToastContent from "components/ToastContent";
import "./styles.scss";
import { SettleModalProps } from "./types";

export default function SettleModal({
	itemForSettleId,
	itemForService,
	confirMessage,
	modalTitle,
	modalInfo,
	isOpen,
	onClose,
	onConfirm,
	kind
}: SettleModalProps) {
	const itemService = new itemForService();

	const confirmSettle = () => {
		if (!itemForSettleId) {
			return;
		}

		itemService
			.settle(itemForSettleId)
			.then(() => {
				toast.dark(
					<ToastContent
						type="success"
						title="Contrato liquidado"
						subtitle={confirMessage}
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				onConfirm();
			})
			.catch((error: unknown) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{ hideProgressBar: true }
				);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={modalTitle}
			cssClass="settle-modal"
			kind={kind}
		>
			<span className="modal-info">{modalInfo}</span>
			<div className="button-section">
				<Button
					kind="default"
					styled="secondary"
					onClick={onClose}
					cssClass="cancel-button"
				>
					Cancelar
				</Button>
				<Button
					kind="default"
					styled="primary"
					onClick={confirmSettle}
					cssClass="confirm-button"
				>
					Confirmar
				</Button>
			</div>
		</Modal>
	);
}
