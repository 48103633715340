import colors from "colors";
import { IconProps } from "./types";

export default function IcoCheckRounded({
	color = colors.neutral["low-pure-500"],
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.28516 8C2.28516 4.68629 4.97145 2 8.28516 2C11.5989 2 14.2852 4.68629 14.2852 8C14.2852 11.3137 11.5989 14 8.28516 14C4.97145 14 2.28516 11.3137 2.28516 8Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.28516 8.2L7.68516 10L11.2852 6"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
