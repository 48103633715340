import { SortingState } from "@tanstack/react-table";
import { BaseModel } from "models/BaseModel";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { deleteParam, setParam, setSearch } from "store/features/search/slice";
import { ObjectWithAnyValues } from "utils/types";

export function useQuerystringParams<FilterType extends ObjectWithAnyValues>(
	setFilters: (value: any) => void,
	sorting: SortingState,
	setSorting: (value: any) => void
) {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const clearQueryString = (obj: FilterType) => {
		Object.keys(obj).forEach((key) => {
			if (
				obj[key] === undefined ||
				obj[key] === "" ||
				(Array.isArray(obj[key]) && obj[key].length === 0)
			) {
				delete obj[key];
			}
		});
		setFilters(obj);
		return obj;
	};

	const updateSort = (sortQuery: string | undefined) => {
		if (!sortQuery || typeof sortQuery !== "string") return;

		const [id, desc] = sortQuery.split(",");
		const parsedSort = { id, desc: false };

		if (desc && desc.trim() === "desc") {
			parsedSort.desc = true;
		}

		if (
			sorting[0]?.id !== parsedSort.id ||
			(sorting[0]?.id === parsedSort.id && sorting[0]?.desc !== parsedSort.desc)
		) {
			setSorting([parsedSort]);
		}
	};

	const updateUrl = (newFilters: FilterType) => {
		updateSort(newFilters.sort);
		const search = new URLSearchParams(clearQueryString(newFilters));
		dispatch(setSearch(search));
		navigate(`${location.pathname}?${search}`);
	};

	return { updateUrl };
}

export function useQuerystringFilters(filters: ObjectWithAnyValues) {
	const [searchParams] = useSearchParams();
	const { search } = useSelector((state: any) => state.search);
	const urlFilters = {
		size: 10,
		page: 1
	} as ObjectWithAnyValues;

	const getUrlFilters = () => {
		let params = searchParams;
		if (searchParams.size === 0) {
			if (
				search instanceof URLSearchParams
					? search.size === 0
					: Object.keys(search).length === 0
			) {
				return {};
			}
			params = search;
		}

		params.forEach((value, key) => {
			if (typeof filters[key] === "object") {
				urlFilters[key] = params.get(key)?.split(",");
			} else if (key === "size" || key === "page") {
				urlFilters[key] = parseInt(value, 10);
			} else {
				urlFilters[key] = value;
			}
		});
		return urlFilters;
	};

	return { getUrlFilters };
}

export function useQuerystringDetails<Model extends BaseModel>(
	allItems: Model[],
	paramId: string,
	setFilters: (value: any) => void
) {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [itemForInspection, setItemForInspection] = useState<Model>();

	const getItemForInspectionFromUrl = () => {
		if (searchParams.get("details")) {
			const aaa = allItems.find(
				(item: any) => item[paramId].toString() === searchParams.get("details")
			);
			setItemForInspection(aaa);
		}
	};

	const setItemForInspectionInUrl = (item: Model | undefined) => {
		setItemForInspection(item);
		if (item) {
			searchParams.set("details", (item as any)[paramId]);
			dispatch(setParam(["details", (item as any)[paramId]]));
		} else {
			searchParams.delete("details");
			dispatch(deleteParam("details"));
			setFilters((oldFilters: any) => ({ ...oldFilters, details: "" }));
		}
		navigate(`${location.pathname}?${searchParams}`);
	};

	return {
		itemForInspection,
		getItemForInspectionFromUrl,
		setItemForInspectionInUrl
	};
}
