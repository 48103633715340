import { useFormik } from "formik";
import { useEffect } from "react";

import { IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import { Button, DatePicker, Drawer } from "components";

import MultiselectFilter from "components/MultiselectFilter";
import "./styles.scss";
import { UserFilterDrawerProps } from "./types";

export default function UserFilterDrawer({
	onApplyFilters,
	isOpen,
	onClickOutside,
	allTeamList,
	roleList,
	...filters
}: UserFilterDrawerProps) {
	const formik = useFormik({
		initialValues: { ...filters },
		onSubmit: (data) => {
			const dataFormat = {
				fromDate:
					typeof data.fromDateValue === "string"
						? data.fromDateValue
						: data.fromDateValue instanceof Date
						? data.fromDateValue.toISOString().split("T")[0]
						: "",
				untilDate:
					typeof data.untilDateValue === "string"
						? data.untilDateValue
						: data.untilDateValue instanceof Date
						? data.untilDateValue.toISOString().split("T")[0]
						: "",
				teams: data.teams ? data.teams : [],
				roles: data.roles ? data.roles : []
			};
			const newFilters = {
				...dataFormat,
				search: filters.search
			};

			onApplyFilters(newFilters);
		},
		onReset: () => {
			formik.setValues({});
			onApplyFilters({});
		}
	});

	const onClose = () => {
		formik.setValues({ ...filters });
		onClickOutside();
	};

	useEffect(() => {
		formik.setFieldValue("roles", filters.roles);
	}, [filters.roles]);

	useEffect(() => {
		formik.setFieldValue("teams", filters.teams);
	}, [filters.teams]);

	useEffect(() => {
		formik.setFieldValue("fromDate", filters.fromDate);
	}, [filters.fromDate]);

	useEffect(() => {
		formik.setFieldValue("untilDate", filters.untilDate);
	}, [filters.untilDate]);

	const FormatRole = (roleName: string) => {
		switch (roleName) {
			case "ADMIN":
				return "Master";
			case "MANAGER":
				return "Gestor";
			case "ANALYST":
				return "Analista";
			default:
				return roleName;
		}
	};
	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Filtro">
			<form
				className="users-filters-form"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="inputs-section">
					<div className="date-group">
						<DatePicker
							label="De"
							name="fromDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("fromDateValue", date);
							}}
							value={formik.values.fromDateValue}
						/>
						<DatePicker
							label="Até"
							name="untilDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("untilDateValue", date);
							}}
							value={formik.values.untilDateValue}
							min={formik.values.fromDateValue}
						/>
					</div>
					<div className="role-group">
						<span className="role-label">Perfil do usuário</span>
						<div className="role-selector">
							{roleList.map((role) => (
								<div key={`role-check-${role.name}`}>
									<input
										id={`role-check-${role.name}`}
										key={`role-checkbox-${role.name}`}
										type="checkbox"
										value={role.name}
										name="roles"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										checked={formik.values.roles?.includes(role.name)}
									/>
									<label
										htmlFor={`role-check-${role.name}`}
										key={`role-check-label-${role.name}`}
									>
										{FormatRole(role.name)}
									</label>
								</div>
							))}
						</div>
					</div>
					<MultiselectFilter
						allItems={allTeamList}
						valueProperty="id"
						labelProperty="name"
						label="Times vinculados"
						name="teams"
						formik={formik}
					/>
				</div>
				<div className="actions-section">
					<Button
						cssClass="reset-filters"
						kind="default"
						styled="secondary"
						type="reset"
					>
						<IcoTrash color={colors.neutral["low-pure-500"]} />
						Limpar filtro
					</Button>
					<Button
						cssClass="submit-filters"
						kind="default"
						styled="primary"
						type="submit"
					>
						<IcoFilter color={colors.neutral["high-pure-50"]} />
						Filtrar
					</Button>
				</div>
			</form>
		</Drawer>
	);
}
