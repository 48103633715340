import { IconProps } from "./types";

export default function IcoFileSecondary({
	color = "#ffffff",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.7143 4V9H19M14.7143 4H5V20H19V9M14.7143 4L19 9"
				stroke="#1D1D1D"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill={color}
			/>
		</svg>
	);
}
