import { useEffect } from "react";

export const useHandleBack = (navigate: any) => {
	const handleBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		window.addEventListener("popstate", handleBack);

		return () => {
			window.removeEventListener("popstate", handleBack);
		};
	}, [navigate]);
};
