import { BaseServices } from "./baseServices";

export class FileService extends BaseServices<any> {
	constructor() {
		super(undefined);
	}

	async downloadFile(fileName: string, containerName: string) {
		const params = {
			fileName,
			containerName
		};
		try {
			const response: Blob = await this.get("blobs/download", params);
			return response;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	async uploadFile(file: File, containerName: string) {
		const config = {
			headers: { "Content-Type": "multipart/form-data" }
		};
		const payload = {
			file,
			containerName
		};
		return this.post(`blobs/upload`, payload, config);
	}
}
