import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { IcoClose, IcoFilter, IcoSearch } from "assets/icons";
import { Avatar, Button } from "components";

import colors from "colors";
import { useQuerystringFilters } from "hooks/querystringFilter";

import { ContractingCompany } from "models/covenant/ContractingCompany";
import { Covenant } from "models/covenant/Covenant";
import { ContractingCompanyService } from "services/companies";
import { CovenantService } from "services/covenants";

import ToastContent from "components/ToastContent";
import CategoryFilterDrawer from "./FilterDrawer";

import { CategoryFilter } from "../types";

import "./styles.scss";

export default function CategoriesListFilters({
	onApply
}: {
	onApply: (filters: any) => void;
}) {
	const emptyFilters = {
		textFilter: "",
		contractingCompanies: [],
		covenants: []
	};
	const [filters, setFilters] = useState<CategoryFilter>(emptyFilters);
	const [qtyFiltersApplied, setQtyFiltersApplied] = useState(0);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [contractingCompanies, setContractingCompanies] = useState<
		ContractingCompany[]
	>([]);
	const [covenants, setCovenants] = useState<Covenant[]>([]);
	const { getUrlFilters } = useQuerystringFilters(filters);
	const contractingCompanyService = new ContractingCompanyService();
	const covenantService = new CovenantService();

	const [isSearch, setIsSearch] = useState(false);

	const onApplyFilters = (appliedFilters: any) => {
		let qtyFilters = 0;
		if (appliedFilters.contractingCompanies?.length) {
			qtyFilters++;
		}
		if (appliedFilters.covenants?.length) {
			qtyFilters++;
		}

		setQtyFiltersApplied(qtyFilters);
		setSearchText(appliedFilters.textFilter || "");

		setFilters({ ...appliedFilters });
		onApply({ ...appliedFilters });
		setIsDrawerOpen(false);
	};

	const getContractingCompanies = () => {
		contractingCompanyService
			.listContractingCompanies()
			.then((response) => setContractingCompanies(response))
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const getCovenants = () => {
		covenantService
			.listCovenants({})
			.then((response) => setCovenants(response))
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	useEffect(() => {
		getContractingCompanies();
		getCovenants();
		onApplyFilters(getUrlFilters());
	}, []);

	const onSearchInputApply = (event: React.SyntheticEvent) => {
		if (
			(event as React.KeyboardEvent).key === "Enter" ||
			(event as React.MouseEvent).type === "click"
		) {
			const newFilters = {
				...filters,
				textFilter: searchText
			};

			setFilters(newFilters);
			onApply(newFilters);
			if (searchText.length > 0) {
				setIsSearch(true);
			}
		}
	};

	const resetFilters = () => {
		setSearchText("");
		setIsSearch(false);
		setFilters(emptyFilters);
		setQtyFiltersApplied(0);
		onApply(emptyFilters);
		setIsDrawerOpen(false);
	};

	const resetSearch = () => {
		setSearchText("");
		setIsSearch(false);
		const newFilters = {
			...filters,
			textFilter: ""
		};
		onApply(newFilters);
	};

	return (
		<>
			<div className="clause-filter-wrapper">
				<div>
					{qtyFiltersApplied > 0 && (
						<Button
							kind="link"
							styled="ghost"
							onClick={resetFilters}
							cssClass="clear-filters-button"
						>
							Limpar filtros
						</Button>
					)}
					<Button
						kind="chip"
						styled={qtyFiltersApplied > 0 ? "primary" : "secondary"}
						onClick={() => setIsDrawerOpen(true)}
						cssClass="filter-button"
					>
						{qtyFiltersApplied === 0 && (
							<IcoFilter color={colors.neutral["low-pure-500"]} />
						)}
						Filtro
						{qtyFiltersApplied > 0 && (
							<Avatar size="sm" cssClass="filter-qty">
								{qtyFiltersApplied}
							</Avatar>
						)}
					</Button>
				</div>
				<div className="divider" />
				<div className="search-filter">
					<input
						placeholder="Pesquisa"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						onKeyDown={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					/>
					<div
						className="search-icon-wrapper"
						onClick={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					>
						{isSearch ? (
							<IcoClose size="1rem" />
						) : (
							<IcoSearch color={colors.neutral["low-pure-500"]} size="1rem" />
						)}
					</div>
				</div>
			</div>
			<CategoryFilterDrawer
				contractingCompanies={filters.contractingCompanies}
				covenants={filters.covenants}
				onApplyFilters={onApplyFilters}
				isOpen={isDrawerOpen}
				onClickOutside={() => setIsDrawerOpen(false)}
				allContractingCompanies={contractingCompanies}
				allCovenants={covenants}
			/>
		</>
	);
}
