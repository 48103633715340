import { useFormik } from "formik";
import { useEffect } from "react";

import { IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import { Button, Drawer } from "components";

import MultiselectFilter from "components/MultiselectFilter";
import { CategoryFilterDrawerProps } from "../../types";
import "./styles.scss";

export default function CategoryFilterDrawer({
	onApplyFilters,
	isOpen,
	onClickOutside,
	allContractingCompanies,
	allCovenants,
	...filters
}: CategoryFilterDrawerProps) {
	const formik = useFormik({
		initialValues: { ...filters },
		onSubmit: (data) => {
			console.dir(data);
			onApplyFilters(data);
		},
		onReset: () => {
			formik.setValues({});
			onApplyFilters({});
		}
	});

	const onClose = () => {
		formik.setValues({ ...filters });
		onClickOutside();
	};

	useEffect(() => {
		formik.setFieldValue("covenants", filters.covenants);
	}, [filters.covenants]);

	useEffect(() => {
		formik.setFieldValue("contractingCompanies", filters.contractingCompanies);
	}, [filters.contractingCompanies]);

	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Filtro">
			<form
				className="category-filters-form"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="inputs-section">
					<MultiselectFilter
						allItems={allContractingCompanies}
						valueProperty="id"
						labelProperty="name"
						label="Contratante"
						name="contractingCompanies"
						formik={formik}
					/>
					<MultiselectFilter
						allItems={allCovenants}
						valueProperty="covenantNumber"
						labelProperty="covenantNumber"
						label="Contrato"
						name="covenants"
						formik={formik}
					/>
				</div>
				<div className="actions-section">
					<Button
						cssClass="reset-filters"
						kind="default"
						styled="secondary"
						type="reset"
					>
						<IcoTrash color={colors.neutral["low-pure-500"]} />
						Limpar filtros
					</Button>
					<Button
						cssClass="submit-filters"
						kind="default"
						styled="primary"
						type="submit"
					>
						<IcoFilter color={colors.neutral["high-pure-50"]} />
						Filtrar
					</Button>
				</div>
			</form>
		</Drawer>
	);
}
