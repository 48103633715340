import clsx from "clsx";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Avatar, Button } from "components";

import { CookieHelper } from "helpers";
import { RootState } from "store";
import { setLogout } from "store/features/auth/slice";

import { AuthService } from "services/auth";
import { HeaderProps } from "../types";
import "./styles.scss";

export default function Profile({ account, onClose }: HeaderProps) {
	const { user } = useSelector((state: RootState) => state.auth);
	const [toggleMenuSupport, setToggleMenuSupport] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authService = new AuthService();

	const handleLogout = async () => {
		dispatch(setLogout());

		sessionStorage.removeItem(authService.USER_DATA);
		CookieHelper.removeCookie(authService.ACCESS_TOKEN);

		navigate("/");
		if (onClose) {
			onClose();
		}
	};

	return (
		<div
			className={clsx("wrapper-profile", {
				open: toggleMenuSupport
			})}
		>
			<div
				className="profile-name-wrapper"
				onClick={() => setToggleMenuSupport(!toggleMenuSupport)}
			>
				<Avatar size="lg">{account?.name.charAt(0)}</Avatar>
				<span className="profile-info">
					<p className="profile-name">{account?.name}</p>
					<p className="profile-role">{account?.role}</p>
				</span>
			</div>
			<div className="profile-options">
				<div className="divider" />
				<Button
					kind="default"
					styled="ghost"
					onClick={handleLogout}
					disabled={!user}
					cssClass="logout"
				>
					Sair
				</Button>
			</div>
		</div>
	);
}
