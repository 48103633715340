import { IcoInfoCircle, IcoSignalError } from "assets/icons";
import AlertBanner, { AlertType } from "components/AlertBanner";
import Button from "components/Button";
import Drawer from "components/Drawer";
import { DrawerProps } from "components/Drawer/types";

import colors from "colors";

import "./styles.scss";

export type ActionDrawerProps = DrawerProps & {
	hasErrors?: boolean;
	alertTitle: string;
	alertMessage: string;
	alertType?: AlertType;
	alertErrorTitle?: string;
	alertErrorMessage?: string;
	onConfirm: () => void;
	confirmButtonDisabled?: boolean;
};

export default function ActionDrawer({
	title,
	direction = "right",
	isOpen,
	children,
	onClose = () => {},
	cssClass,
	hasErrors = false,
	alertTitle,
	alertMessage,
	alertType = "alert",
	alertErrorTitle,
	alertErrorMessage,
	onConfirm,
	confirmButtonDisabled = false
}: ActionDrawerProps) {
	const alertIconColor =
		alertType === "warning"
			? colors["feedback-alert"]["pure-500"]
			: alertType === "error"
			? colors["feedback-negative"]["pure-500"]
			: colors.neutral["low-pure-500"];

	return (
		<Drawer
			isOpen={isOpen}
			onClose={onClose}
			title={title}
			cssClass={cssClass}
			direction={direction}
		>
			<div className="action-drawer-content">
				<div className="form stack--6">
					{!hasErrors ? (
						<AlertBanner
							type={alertType}
							title={alertTitle}
							message={alertMessage}
							icon={<IcoInfoCircle color={alertIconColor} />}
						/>
					) : (
						<AlertBanner
							type="error"
							title={alertErrorTitle ?? alertTitle}
							message={alertErrorMessage ?? alertMessage}
							icon={<IcoSignalError />}
						/>
					)}
					{children}
				</div>
				<div className="actions-section">
					{!hasErrors ? (
						<>
							<Button
								styled="secondary"
								cssClass="cancel-button"
								onClick={onClose}
							>
								Cancelar
							</Button>
							<Button
								cssClass="save-button"
								onClick={onConfirm}
								disabled={confirmButtonDisabled}
							>
								Alterar
							</Button>
						</>
					) : (
						<Button onClick={onClose}>Voltar</Button>
					)}
				</div>
			</div>
		</Drawer>
	);
}
