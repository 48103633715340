import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
	Button,
	Container,
	EmptyList as EmptyCovenantsList,
	Loading
} from "components";
import { CovenantService } from "services/covenants";

import { SortingState } from "@tanstack/react-table";
import { IcoPlus } from "assets/icons";
import colors from "colors";
import ToastContent from "components/ToastContent";
import TableHelper from "helpers/TableHelper";
import { useQuerystringParams } from "hooks/querystringFilter";
import { useHandleBack } from "hooks/useHandleBack";
import { useNavigate } from "react-router-dom";
import CovenantsListFilters from "./CovenantsListFilters";
import CovenantsTable from "./CovenantsTable";
import "./styles.scss";
import { CovenantFilter } from "./types";

export default function CovenantList() {
	const navigate = useNavigate();
	const covenantService = new CovenantService();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [covenants, setCovenants] = useState<any>([]);
	const [filters, setFilters] = useState<CovenantFilter>({
		size: 10,
		page: 1
	});
	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<CovenantFilter>(
		setFilters,
		sorting,
		setSorting
	);

	const parseParams = () => {
		const sortParam = TableHelper.sortingStateToParam(sorting);
		const filtersParams = {
			...filters,
			contractingCompaniesIds: filters.contractingCompanies
		};
		if ("contractingCompanies" in filtersParams) {
			delete filtersParams.contractingCompanies;
		}
		return {
			...sortParam,
			...filtersParams
		};
	};

	const getCovenants = () => {
		setIsFetchingData(true);

		covenantService
			.listCovenantsPaginated(parseParams())
			.then((response) => {
				setCovenants(response.content);
				setTotalPages(response.totalPages);
				setIsFetchingData(false);
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	useEffect(() => {
		if (initialized) {
			getCovenants();
		} else {
			setInitialized(true);
		}
	}, [filters]);

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;
		const newFilters = {
			size: filters.size,
			page: 1,
			...appliedFilters,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};
		updateUrl(newFilters);
	};

	useHandleBack(navigate);

	return (
		<Container className="covenants-page">
			<div className="covenants-heading">
				<span className="covenants-title">Contratos</span>
				<CovenantsListFilters onApply={onApplyFilters} />
				<Button
					kind="default"
					styled="primary"
					cssClass="create-covenant-button"
					onClick={() => navigate("/covenants/create")}
				>
					<IcoPlus color={colors.neutral["high-pure-50"]} />
					<span>Criar contrato</span>
				</Button>
			</div>
			{isFetchingData ? (
				<Loading type="primary" />
			) : covenants.length === 0 ? (
				<EmptyCovenantsList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<CovenantsTable
					content={covenants}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
				/>
			)}
		</Container>
	);
}
