import { IcoArrowRight, IcoCalendar } from "assets/icons";
import pt from "date-fns/locale/pt-BR";
import React, { SyntheticEvent } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

interface DatePickerProps {
	label: string;
	error?: string | undefined;
	name?: string;
	className?: string;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange: (
		date: Date | null,
		event: SyntheticEvent<any, Event> | undefined
	) => void;
	value?: Date | null | any;
	min?: Date;
	disabled?: boolean;
	showYearPicker?: boolean;
	showMonthYearPicker?: boolean;
}

registerLocale("pt-BR", pt);

export default function DatePickerComponent({
	label,
	error,
	name,
	className,
	onBlur,
	onChange,
	value,
	min,
	disabled,
	showMonthYearPicker,
	showYearPicker
}: DatePickerProps) {
	return (
		<div className="date-picker-container">
			<span
				className={
					disabled ? "date-picker-label-disabled" : "date-picker-label"
				}
			>
				<IcoArrowRight />
				{label}
			</span>
			<DatePicker
				autoComplete="off"
				selected={value}
				onChange={onChange}
				onBlur={onBlur}
				className={`date-picker-input ${className || ""}`}
				placeholderText="00/00/00"
				minDate={min}
				disabled={disabled}
				dateFormat="dd/MM/yyyy"
				name={name}
				locale="pt-BR"
				icon={<IcoCalendar />}
				showIcon
				peekNextMonth
				showMonthYearPicker={showMonthYearPicker}
				showYearPicker={showYearPicker}
				dropdownMode="scroll"
			/>
			{error && <div className="error-message">{error}</div>}
		</div>
	);
}
