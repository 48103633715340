import { IconProps } from "./types";

export default function IcoCheck({
	color = "#1D1D1D",
	size = "27"
}: IconProps) {
	let sizeH = 18;
	if (size.includes("rem")) {
		sizeH = parseFloat(size) - parseFloat(size) * (38 / 100);
	}

	return (
		<svg
			width={size}
			height={`${sizeH}rem`}
			viewBox="0 0 27 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.28564 9L10.4856 17L25.2856 1"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
