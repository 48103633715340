import colors from "colors";
import { IconProps } from "./types";

export default function IcoArrowLeft({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.1562 18.8125L12.7812 18.2188C12.9375 18.0625 12.9375 17.8125 12.7812 17.6875L7.9375 12.8125H18.625C18.8125 12.8125 19 12.6562 19 12.4375V11.5625C19 11.375 18.8125 11.1875 18.625 11.1875H7.9375L12.7812 6.34375C12.9375 6.21875 12.9375 5.96875 12.7812 5.8125L12.1562 5.21875C12.0312 5.0625 11.7812 5.0625 11.625 5.21875L5.09375 11.75C4.9375 11.9062 4.9375 12.125 5.09375 12.2812L11.625 18.8125C11.7812 18.9688 12.0312 18.9688 12.1562 18.8125Z"
				fill={color}
			/>
		</svg>
	);
}
