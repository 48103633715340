const colors = {
	neutral: {
		"low-300": "#999999",
		"low-400": "#666666",
		"low-pure-500": "#1D1D1D",
		"high-pure-50": "#FFFFFF",
		"high-100": "#F0F0F0",
		"high-200": "#DBDBDB",
		"gray-border": "#C6C7C8",
		1000: "#000000",
		900: "#1D1D1D",
		800: "#37393C",
		700: "#45484B",
		600: "#53565A",
		500: "#707275",
		400: "#8C8E91",
		300: "#A9ABAD",
		200: "#C6C7C8",
		100: "#ECECED",
		0: "#FFFFFF"
	},
	"feedback-positive": {
		"low-950": "#1A2F2A",
		"pure-500": "#00D4A1",
		"high-50": "#E6F8F4",
		"pure-100": "#1DC49C"
	},
	"feedback-alert": {
		"low-950": "#322E21",
		"pure-500": "#F6C643",
		"high-50": "#FEF9E6"
	},
	"feedback-negative": {
		"low-950": "#331F21",
		"pure-500": "#DE557C",
		"high-50": "#FCEEF2",
		"pure-ds": "#FEE7EC"
	},
	"feedback-focus": {
		"low-950": "#272732",
		"pure-500": "#8887F3",
		"high-50": "#F3F3FE"
	},
	feedback: {
		positive: {
			900: "#002A20",
			800: "#00533F",
			700: "#007D5F",
			600: "#00A77F",
			500: "#1DC49C",
			400: "#55D2B4",
			300: "#8EE1CD",
			200: "#C6F0E6",
			100: "#E6F8F4"
		},
		negative: {
			900: "#35030F",
			800: "#6B071F",
			700: "#A00A2E",
			600: "#D50D3D",
			500: "#E4204E",
			400: "#F55F83",
			300: "#F894AC",
			200: "#FCCAD6",
			100: "#FEE7EC"
		},
		alert: {
			900: "#372B01",
			800: "#6F5503",
			700: "#A68004",
			600: "#DDAA05",
			500: "#F9C006",
			400: "#FBD559",
			300: "#FCE390",
			200: "#FEF1C8",
			100: "#FEF9E6"
		}
	},
	branding: {
		highway: {
			900: "#181834",
			800: "#313068",
			700: "#49489C",
			600: "#6260D0",
			500: "#7E7CEC",
			400: "#9E9DF1",
			300: "#BFBEF6",
			200: "#DFDEFA",
			100: "#F1F0FD"
		},
		airport: {
			900: "#0F2938",
			800: "#1E5270",
			700: "#2D7BA8",
			600: "#3CA4E0",
			500: "#59C1FC",
			400: "#82D0FD",
			300: "#ACE0FE",
			200: "#D5EFFE",
			100: "#ECF8FF"
		},
		mobility: {
			900: "#391808",
			800: "#713010",
			700: "#AA4817",
			600: "#E2601F",
			500: "#FF7C3C",
			400: "#FF9D6C",
			300: "#FFBE9D",
			200: "#FFDECE",
			100: "#FFF0E9"
		},
		company: {
			900: "#1F0A07",
			800: "#3E140E",
			700: "#5C1F16",
			600: "#7B291D",
			500: "#9E270E",
			400: "#AE5C50",
			300: "#C2857C",
			200: "#D7ADA7",
			100: "#EBD6D3"
		}
	},
	charts: {
		pineapple: {
			900: "#261B02",
			800: "#4C3605",
			700: "#735107",
			600: "#996C0A",
			500: "#B58926",
			400: "#C8A65C",
			300: "#DAC493",
			200: "#EDE1C9",
			100: "#F7F2E7"
		},
		carrot: {
			900: "#391D0A",
			800: "#713A13",
			700: "#AA571D",
			600: "#E27426",
			500: "#FF9143",
			400: "#FFAC72",
			300: "#FFC8A1",
			200: "#FFE3D0",
			100: "#FFF3EA"
		},
		cherry: {
			900: "#39090E",
			800: "#71121B",
			700: "#AA1B29",
			600: "#E22436",
			500: "#FF4053",
			400: "#FF707E",
			300: "#FFA0A9",
			200: "#FFCFD4",
			100: "#FFEAEC"
		},
		pomegranate: {
			900: "#330033",
			800: "#660066",
			700: "#990099",
			600: "#CC00CC",
			500: "#FF00FF",
			400: "#FF33FF",
			300: "#FF66FF",
			200: "#FF99FF",
			100: "#FFCCFF"
		},
		grape: {
			900: "#231538",
			800: "#452971",
			700: "#683EA9",
			600: "#8B53E2",
			500: "#A76FFE",
			400: "#BD93FE",
			300: "#D3B7FF",
			200: "#E9DBFF",
			100: "#F5EFFF"
		},
		bluebarry: {
			900: "#001E33",
			800: "#003C65",
			700: "#005A98",
			600: "#0078CA",
			500: "#1D94E7",
			400: "#55AFED",
			300: "#8ECAF3",
			200: "#C6E4F9",
			100: "#E6F3FC"
		},
		lime: {
			900: "#001B1C",
			800: "#003538",
			700: "#005055",
			600: "#006B71",
			500: "#208388",
			400: "#55A5AA",
			300: "#8EC3C6",
			200: "#C6E1E3",
			100: "#E6F2F2"
		}
	}
};

module.exports = colors;
