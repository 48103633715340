const FileHelper = {
	downloadFile(file: any, fileName: string, fileType: string) {
		const mimeType = fileType;
		const url = window.URL.createObjectURL(
			new Blob([file], { type: mimeType })
		);

		const temporaryLink = document.createElement("a");
		temporaryLink.href = url;
		temporaryLink.download = fileName;
		document.body.appendChild(temporaryLink);
		temporaryLink.click();
		window.URL.revokeObjectURL(url);
		temporaryLink.parentNode?.removeChild(temporaryLink);
	},

	downloadExcelFile(file: any, fileName: string) {
		this.downloadFile(
			file,
			fileName,
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		);
	}
};

export default FileHelper;
