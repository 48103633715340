import { useFormik } from "formik";
import { useEffect } from "react";

import { IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import { Button, DatePicker, Drawer } from "components";

import "./styles.scss";
import { TeamFilterDrawerProps } from "./types";

export default function TeamFilterDrawer({
	onApplyFilters,
	isOpen,
	onClickOutside,
	allTeamList,
	roleList,
	...filters
}: TeamFilterDrawerProps) {
	const formik = useFormik({
		initialValues: { ...filters },
		onSubmit: (data) => {
			console.dir(data);

			const dataFormat = {
				fromDate:
					typeof data.fromDateValue === "string"
						? data.fromDateValue
						: data.fromDateValue instanceof Date
						? data.fromDateValue.toISOString().split("T")[0]
						: "",
				untilDate:
					typeof data.untilDateValue === "string"
						? data.untilDateValue
						: data.untilDateValue instanceof Date
						? data.untilDateValue.toISOString().split("T")[0]
						: ""
			};

			onApplyFilters(dataFormat);
		},
		onReset: () => {
			formik.setValues({});
			onApplyFilters({});
		}
	});

	const onClose = () => {
		formik.setValues({ ...filters });
		onClickOutside();
	};

	useEffect(() => {
		formik.setFieldValue("fromDate", filters.fromDate);
	}, [filters.fromDate]);

	useEffect(() => {
		formik.setFieldValue("untilDate", filters.untilDate);
	}, [filters.untilDate]);

	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Filtro">
			<form
				className="filters-form"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="inputs-section">
					<div className="date-group">
						<DatePicker
							label="De"
							name="fromDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("fromDateValue", date);
							}}
							value={formik.values.fromDateValue}
						/>
						<DatePicker
							label="Até"
							name="untilDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("untilDateValue", date);
							}}
							value={formik.values.untilDateValue}
							min={formik.values.fromDateValue}
						/>
					</div>
				</div>
				<div className="actions-section">
					<Button
						cssClass="reset-filters"
						kind="default"
						styled="secondary"
						type="reset"
					>
						<IcoTrash color={colors.neutral["low-pure-500"]} />
						Limpar filtro
					</Button>
					<Button
						cssClass="submit-filters"
						kind="default"
						styled="primary"
						type="submit"
					>
						<IcoFilter color={colors.neutral["high-pure-50"]} />
						Filtrar
					</Button>
				</div>
			</form>
		</Drawer>
	);
}
