import { ErrorHelper } from "helpers";
import { Team } from "models/auth/Team";
import { CodeName } from "models/types";
import moment from "moment";
import * as Yup from "yup";
import { BaseModel } from "../BaseModel";
import { ContractingCompany } from "./ContractingCompany";
import { CovenantStatus, Currency } from "./enums";
import { CovenantCreationEditionProps, CovenantProps } from "./types";

export class Covenant extends BaseModel {
	covenantId: string;
	covenantType: CodeName;
	covenantNumber: string;
	currency: CodeName;
	totalValue: number;
	signatureDate: Date;
	expirationDate: Date;
	status: CodeName;
	description: string;
	covenantPdf: string;
	covenantPdfFile?: File;
	contractingCompanies: ContractingCompany[];
	contractingType: CodeName;
	creditor: CodeName;
	creditorType: CodeName;
	clauses?: any[];
	teams: Team[];

	constructor(data: CovenantProps) {
		super(data);
		this.covenantId = data.covenantId;
		this.covenantType = data.covenantType;
		this.covenantNumber = data.covenantNumber;
		this.currency = data.currency;
		this.totalValue = data.totalValue;
		this.signatureDate = Covenant.getValueOrNew(data.signatureDate, Date);
		this.expirationDate = Covenant.getValueOrNew(data.expirationDate, Date);
		this.status = data.status;
		this.description = data.description;
		this.covenantPdf = data.covenantPdf;
		this.contractingCompanies = data.contractingCompanies?.map((company) =>
			ContractingCompany.getValueOrNew(company)
		);
		this.contractingType = data.contractingType;
		this.creditor = data.creditor;
		this.creditorType = data.creditorType;
		this.teams =
			data.teams?.map((team) => Team.getValueOrNew(team, Team)) ?? [];
		this.clauses = data.clauses || [];
	}

	get contractingCompaniesNames() {
		return this.contractingCompanies.map((company) => company.name).join("; ");
	}

	get hasExpired() {
		return this.expirationDate <= new Date();
	}

	get alreadySettled() {
		return [
			CovenantStatus.LiquidacaoAntecipada.code,
			CovenantStatus.Liquidado.code
		].includes(this.status?.code);
	}

	get exportFileName(): string {
		if (!this.covenantNumber) {
			return "covenant.xlsx";
		}
		let fileName = `${this.covenantNumber}`;
		if (this.signatureDate) {
			fileName = `${fileName} - ${moment(this.signatureDate).format(
				"DD.MM.YYYY"
			)}`;
		}
		return `${fileName} - Planilha de Obrigações.xlsx`;
	}

	get asValues(): CovenantCreationEditionProps {
		return {
			...this,
			covenantType: this.covenantType.code,
			currency: this.currency.code,
			status: this.status?.code,
			contractingType: this.contractingType.code,
			creditor: this.creditor.code,
			creditorType: this.creditorType.code,
			contractingCompanies: this.contractingCompanies.map(
				(company) => company.id
			),
			clauses: this.clauses?.map((clause) => clause.id)
		};
	}

	get asImportValues(): CovenantCreationEditionProps {
		return {
			...this,
			...this.asValues,
			clauses: this.clauses || []
		};
	}

	static get validationSchema() {
		return Yup.object().shape({
			covenantType: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			covenantNumber: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			currency: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			totalValue: Yup.number().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			signatureDate: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			expirationDate: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			status: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			description: Yup.string()
				.nullable()
				.required(ErrorHelper.errorMessages.fieldRequired),
			covenantPdf: Yup.string()
				.nullable()
				.when("covenantPdfFile", {
					is: (covenantPdfFile: File) => covenantPdfFile === undefined,
					then: Yup.string().required(ErrorHelper.errorMessages.fieldRequired)
				}),
			contractingType: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			creditor: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			creditorType: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			contractingCompanies: Yup.array()
				.of(Yup.string())
				.min(1, ErrorHelper.errorMessages.fieldRequired)
				.required(ErrorHelper.errorMessages.fieldRequired)
		});
	}

	static get initialValues() {
		return { currency: Currency.BRL.code } as CovenantCreationEditionProps;
	}

	static toRequest(values: CovenantCreationEditionProps) {
		const newValues = {
			...values,
			signatureDate: moment(values?.signatureDate).toISOString(),
			expirationDate: moment(values?.expirationDate).toISOString(),
			contractingCompaniesIds: values?.contractingCompanies
		};

		if (values.covenantId === null && values.clauses) {
			newValues.clauses = values.clauses?.map((clause) => {
				return {
					...clause,
					status: clause.status.code,
					frequency: clause.monitoring[0].frequency.code,
					categoryId: clause.category.id as string,
					monitoringList: clause.monitoring.map((monitoring: any) => {
						return {
							...monitoring,
							level: monitoring.level.code,
							lastExecutedAt: moment(monitoring.lastExecutedAt).toISOString(),
							task: {
								...monitoring.task,
								status: monitoring.task.status.code
							}
						};
					})
				};
			});
		}

		return newValues;
	}
}
