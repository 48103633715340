import { Category } from "models/covenant/Category";
import { CategoryCreationProps } from "models/covenant/types";

import { ObjectWithAnyValues } from "utils/types";

import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class CategoryService extends BaseServices<Category> {
	constructor() {
		super(Category);
	}

	async listCategoriesPaginated(
		params: ObjectWithAnyValues
	): Promise<BasePageResponse> {
		return super.listPaginated("clause-categories", params);
	}

	async listCategories(params: any): Promise<Category[]> {
		return this.list("clause-categories/list", params);
	}

	async createCategory(category: CategoryCreationProps) {
		return this.post("clause-categories", category);
	}
}
