/* eslint-disable no-debugger */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { baseConfig } from "config";
import { CookieHelper, ErrorHelper } from "helpers";
import AuthHelper from "helpers/AuthHelper";
import { User } from "models/auth/User";
import { toast } from "react-toastify";
import { setLogout } from "store/features/auth/slice";
import { BaseServices } from "./baseServices";

export class AuthService extends BaseServices<User> {
	constructor() {
		super(User);
	}

	ACCESS_TOKEN = "access_token";
	USER_DATA = "user_data";
	NEXT = "next_uri";
	ERROR = "error";

	async login() {
		const next_uri = `${window.location.pathname}${
			window.location.search ?? ""
		}`;
		const redirect_uri = `${window.location.protocol}//${window.location.host}/oauth2/redirect?${this.NEXT}=${next_uri}`;
		if (baseConfig.mockAuth) {
			window.location.href = redirect_uri;
			return;
		}
		const loginUrl = `${baseConfig.baseURL}/oauth2/authorize/azure?redirect_uri=${redirect_uri}`;
		window.location.href = loginUrl;
	}

	async logout() {
		return this.get("auth/logout/").catch((error) =>
			toast.error(ErrorHelper.getErrorMessage(error))
		);
	}

	async retrieveLoggedUser() {
		return this.retrieve("/users/me");
	}

	async getCurrentUser() {
		if (!CookieHelper.getCookies(this.ACCESS_TOKEN)) {
			return Promise.reject(new Error("No access token set."));
		}

		if (sessionStorage.getItem(this.USER_DATA)) {
			return Promise.resolve(
				JSON.parse(sessionStorage.getItem(this.USER_DATA)!)
			);
		}

		const response = this.retrieveLoggedUser();

		response.then((user) =>
			sessionStorage.setItem(this.USER_DATA, JSON.stringify(user))
		);
		return response;
	}

	async refreshLogin(dispatch: Dispatch<AnyAction>) {
		const response = this.get("auth/token/refresh/")
			.then(() => AuthHelper.startTokenRefreshTimeout(dispatch))
			.catch((error) => {
				if (error.code === "ERR_NETWORK") {
					AuthHelper.startTokenRefreshTimeout(dispatch);
				} else {
					dispatch(setLogout());
				}
			});
		return response;
	}
}
