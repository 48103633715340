import colors from "colors";
import { IconProps } from "./types";

export default function IcoExport({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 10V2M8 2L12 5.6M8 2L4 5.6M14 10.0039V14.0039L2 14.0039L2 10.0039"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
