import { IcoCheckFilled, IcoClose, IcoCloseFilled } from "assets/icons";
import { ErrorHelper } from "helpers";
import { ToastProps } from "./types";

function ToastContent({
	type,
	title,
	subtitle,
	subtitleError,
	onClose = () => {}
}: ToastProps) {
	return (
		<div
			className={
				type === "error" ? "error-toast-info" : "confirmation-toast-info"
			}
		>
			<div
				className={
					type === "error" ? "toast-header" : "confirmation-toast-header"
				}
			>
				<button className="close-toast-btn" onClick={onClose}>
					<IcoClose />
				</button>
				{type === "error" ? (
					<>
						<IcoCloseFilled />
						<span className="error-toast-title">{title}</span>
						<span className="error-toast-message">
							{ErrorHelper.getErrorMessage(subtitleError)}
						</span>
					</>
				) : (
					<>
						<span className="icon-check">
							<IcoCheckFilled />
						</span>
						<span className="confirmation-toast-title">{title}</span>
						<span className="confirmation-toast-message">{subtitle}</span>
					</>
				)}
			</div>
		</div>
	);
}

export default ToastContent;
