import { IconProps } from "./types";

export default function IcoSearch({
	color = "#999999",
	size = "1rem"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="7.5"
				cy="7.5"
				r="5.5"
				transform="rotate(-90 7.5 7.5)"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5 11.5L13.9979 13.9969"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
