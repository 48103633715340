import { IconProps } from "./types";

export default function IcoFile({ color = "#0D0D0D", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 11.75C15 11.5625 14.8125 11.375 14.625 11.375H9.375C9.15625 11.375 9 11.5625 9 11.75V12.625C9 12.8438 9.15625 13 9.375 13H14.625C14.8125 13 15 12.8438 15 12.625V11.75ZM14.625 14H9.375C9.15625 14 9 14.1875 9 14.375V15.25C9 15.4688 9.15625 15.625 9.375 15.625H14.625C14.8125 15.625 15 15.4688 15 15.25V14.375C15 14.1875 14.8125 14 14.625 14ZM18 8.125C18 7.75 17.8125 7.34375 17.5312 7.0625L14.9375 4.46875C14.6562 4.1875 14.25 4 13.875 4H7.5C6.65625 4 6 4.6875 6 5.5V18.5C6 19.3438 6.65625 20 7.5 20H16.5C17.3125 20 18 19.3438 18 18.5V8.125ZM14 5.625L16.375 8H14V5.625ZM16.5 18.5H7.5V5.5H12.5V8.75C12.5 9.1875 12.8125 9.5 13.25 9.5H16.5V18.5Z"
				fill={color}
			/>
		</svg>
	);
}
