import Multiselect from "components/Multiselect";
import { ValueType } from "components/Multiselect/types";
import { FormikProps } from "formik";
import { SelectHelper } from "helpers/SelectHelper";
import { useEffect, useState } from "react";

export default function MultiselectFilter({
	allItems,
	valueProperty,
	labelProperty,
	label,
	name,
	formik
}: {
	allItems: any[];
	valueProperty: string;
	labelProperty: string;
	label: string;
	name: string;
	formik: FormikProps<any>;
}) {
	const [items, setItems] = useState<ValueType[]>([]);

	useEffect(() => {
		setItems(SelectHelper.toValueLabel(allItems, valueProperty, labelProperty));
	}, [allItems]);

	const onsetInputChange = (value: string) => {
		if (!value) {
			const valueLabelItems = SelectHelper.toValueLabel(
				allItems,
				valueProperty,
				labelProperty
			);
			setItems(valueLabelItems);
		} else {
			const valueLabelFiltered: ValueType[] = [];
			allItems.map((item) => {
				if (
					item[labelProperty]
						.toLowerCase()
						.includes(value.toString().toLowerCase())
				) {
					valueLabelFiltered.push({
						value: item[valueProperty],
						label: item[labelProperty]
					});
				}
				return item;
			});
			setItems(valueLabelFiltered);
		}
	};

	const clearSelection = () => {
		formik.setFieldValue(name, []);
	};

	return (
		<Multiselect
			label={label}
			name={name}
			placeholder="Selecione"
			options={items.sort((a, b) => a.label.localeCompare(b.label))}
			value={items.filter((item) => formik.values[name]?.includes(item.value))}
			onChange={(options) => {
				const selectedOptions = (options as any[]).map((item) => item.value);
				formik.setFieldValue(name, selectedOptions);
			}}
			onInputChange={onsetInputChange}
			onClear={clearSelection}
		/>
	);
}
