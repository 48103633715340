import { ClauseDetailsPage, TaskList, Page404 } from "pages";
import CompleteTaskPage from "pages/Tasks/Complete";
import { Route, Routes } from "react-router-dom";

export default function AnalystRoutes() {
	return (
		<Routes>
			<Route path="/tasks" element={<TaskList />} />
			<Route path="/clauses/details/:id" element={<ClauseDetailsPage />} />
			<Route path="/tasks/complete/:id" element={<CompleteTaskPage />} />
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}
