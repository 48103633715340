import "./styles.scss";

type LabelProps = {
	children: React.ReactNode;
	isRequired: boolean;
	hasDisabled?: boolean;
};
export default function Label({
	children,
	isRequired,
	hasDisabled
}: LabelProps) {
	return (
		<div className={hasDisabled ? "label disabled" : "label"}>
			{children}
			{isRequired && <span>*</span>}
		</div>
	);
}
