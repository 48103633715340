import Label from "components/Label";
import { TextareaHTMLAttributes } from "react";
import "./styles.scss";

type InputProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
	label: string;
	error?: string | undefined;
};

export default function TextArea({
	label,
	placeholder,
	name,
	value,
	required,
	disabled,
	error,
	rows,
	maxLength,
	onChange,
	className
}: InputProps) {
	return (
		<div className="textarea-container">
			<Label hasDisabled={disabled} isRequired={!!required}>
				{label}
			</Label>
			<textarea
				name={name}
				value={value}
				placeholder={placeholder}
				disabled={disabled}
				className={`textarea-component ${className || ""}`}
				required={required}
				rows={rows}
				maxLength={maxLength}
				onChange={onChange}
			/>
			{error && <div className="error-message">{error}</div>}
		</div>
	);
}
