import { IconProps } from "./types";

export default function IcoReverter({
	color = "#1D1D1D",
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 11.8247L5 3.00004M5 3.00004L8 5.9416M5 3.00004L2 5.9416"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 4.17529L11 13M11 13L14 10.0584M11 13L8 10.0584"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
