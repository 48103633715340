import { IconProps } from "./types";

export default function IcoGroup({
	color = "#1D1D1D",
	size = "16"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 19V15.4M12 15.4C12 14.682 11.403 14.1 10.6667 14.1H5.33333C4.59695 14.1 4 14.682 4 15.4V18M12 15.4V14.1C12 13.382 12.597 12.8 13.3333 12.8H18.6667C19.403 12.8 20 13.382 20 14.1V16.7M10.6667 8.9C10.6667 10.3359 9.47276 11.5 8 11.5C6.52724 11.5 5.33333 10.3359 5.33333 8.9C5.33333 7.46406 6.52724 6.3 8 6.3C9.47276 6.3 10.6667 7.46406 10.6667 8.9ZM18.6667 7.6C18.6667 9.03594 17.4728 10.2 16 10.2C14.5272 10.2 13.3333 9.03594 13.3333 7.6C13.3333 6.16406 14.5272 5 16 5C17.4728 5 18.6667 6.16406 18.6667 7.6Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
