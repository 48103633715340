import * as Yup from "yup";

import { ErrorHelper } from "helpers";

import { BaseModel } from "models/BaseModel";

import { CategoryCreationProps, CategoryProps } from "./types";

export class Category extends BaseModel {
	id: string;
	name: string;
	covenantCount?: number;
	clauseCount?: number;

	constructor(data: CategoryProps) {
		super(data);
		this.id = data.id as string;
		this.name = data.name;
		this.covenantCount = data.covenantCount;
		this.clauseCount = data.clauseCount;
	}

	static get initialValues() {
		return { name: "" } as CategoryCreationProps;
	}

	static get validationSchema() {
		return Yup.object().shape({
			name: Yup.string().required(ErrorHelper.errorMessages.fieldRequired)
		});
	}
}
