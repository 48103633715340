import { useFormik } from "formik";
import { useEffect } from "react";

import { IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import { Button, DatePicker, Drawer } from "components";

import MultiselectFilter from "components/MultiselectFilter";
import { CovenantStatusOptions } from "models/covenant/enums";
import "./styles.scss";
import { CovenantFilterDrawerProps } from "./types";

export default function CovenantFilterDrawer({
	onApplyFilters,
	isOpen,
	onClickOutside,
	allContractorList,
	...filters
}: CovenantFilterDrawerProps) {
	const formik = useFormik({
		initialValues: { ...filters },
		onSubmit: (data) => {
			console.dir(data);
			const dataFormat = {
				contractingCompanies: data.contractingCompanies || [],

				fromDate: data.fromDateValue
					? data.fromDateValue.toISOString().split("T")[0]
					: "",
				status: data.status || [],

				untilDate: data.untilDateValue
					? data.untilDateValue.toISOString().split("T")[0]
					: ""
			};
			onApplyFilters(dataFormat);
		},
		onReset: () => {
			formik.setValues({});
			onApplyFilters({});
		}
	});

	const onClose = () => {
		formik.setValues({ ...filters });
		onClickOutside();
	};

	useEffect(() => {
		formik.setFieldValue("status", filters.status);
	}, [filters.status]);

	useEffect(() => {
		formik.setFieldValue("contractingCompanies", filters.contractingCompanies);
	}, [filters.contractingCompanies]);

	useEffect(() => {
		formik.setFieldValue("fromDate", filters.fromDate);
	}, [filters.fromDate]);

	useEffect(() => {
		formik.setFieldValue("untilDate", filters.untilDate);
	}, [filters.untilDate]);

	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Filtro">
			<form
				className="filters-form"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="inputs-section">
					<div className="date-group">
						<DatePicker
							label="De"
							name="fromDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("fromDateValue", date);
							}}
							value={formik.values.fromDateValue}
						/>
						<DatePicker
							label="Até"
							name="untilDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("untilDateValue", date);
							}}
							value={formik.values.untilDateValue}
							min={formik.values.fromDateValue}
						/>
					</div>
					<MultiselectFilter
						allItems={allContractorList}
						valueProperty="id"
						labelProperty="name"
						label="Contratante"
						name="contractingCompanies"
						formik={formik}
					/>
					<div className="status-group">
						<span className="status-label">Status</span>
						<div className="status-selector">
							{CovenantStatusOptions.map((status) => (
								<div key={`status-check-${status.label}`}>
									<input
										id={`status-check-${status.value}`}
										key={`status-checkbox-${status.value}`}
										type="checkbox"
										value={status.value.toString()}
										name="status"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										checked={formik.values.status?.includes(
											status.value.toString()
										)}
									/>
									<label
										htmlFor={`status-check-${status.value}`}
										key={`status-check-label-${status.value}`}
									>
										{status.label}
									</label>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="actions-section">
					<Button
						cssClass="reset-filters"
						kind="default"
						styled="secondary"
						type="reset"
					>
						<IcoTrash color={colors.neutral["low-pure-500"]} />
						Limpar filtro
					</Button>
					<Button
						cssClass="submit-filters"
						kind="default"
						styled="primary"
						type="submit"
					>
						<IcoFilter color={colors.neutral["high-pure-50"]} />
						Filtrar
					</Button>
				</div>
			</form>
		</Drawer>
	);
}
