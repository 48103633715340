import colors from "colors";
import { IconFilledProps } from "./types";

export default function IcoCloseFilled({
	strokeColor = colors.neutral["high-pure-50"],
	bgColor = "#F83446",
	size = "1.5rem"
}: IconFilledProps) {
	let height = 25;
	if (size.includes("rem")) {
		height = parseFloat(size) - parseFloat(size) * (38 / 100);
	} else {
		height = (height * parseInt(size, 10)) / 24 / 16;
	}

	return (
		<svg
			width={size}
			height={`${height}rem`}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5Z"
				fill={bgColor}
				stroke={bgColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 9.5L9.1701 15.3299M14.8359 15.5L9.00603 9.6701"
				stroke={strokeColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
