export const SelectHelper = {
	toValueLabel(items: any[], valueProperty: string, labelProperty: string) {
		return items.map((item) => {
			return {
				value: item[valueProperty],
				label: item[labelProperty]
			};
		});
	}
};
