import { SortingState } from "@tanstack/react-table";
import colors from "colors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
	Button,
	Container,
	EmptyList as EmptyCategoriesList,
	Loading
} from "components";

import TableHelper from "helpers/TableHelper";
import { CategoryService } from "services/categories";

import { useQuerystringParams } from "hooks/querystringFilter";

import { IcoPlus } from "assets/icons";
import ToastContent from "components/ToastContent";
import { useHandleBack } from "hooks/useHandleBack";
import CategoriesListFilters from "./CategoriesListFilters";
import CategoriesTable from "./CategoriesTable";
import { CategoryFilterTable } from "./types";

import "./styles.scss";

export default function CategoryList() {
	const navigate = useNavigate();
	const categoryService = new CategoryService();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [categories, setCategories] = useState<any>([]);
	const [filters, setFilters] = useState<CategoryFilterTable>({
		size: 10,
		page: 1
	});
	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: "name",
			desc: false
		}
	]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<CategoryFilterTable>(
		setFilters,
		sorting,
		setSorting
	);

	const parseParams = () => {
		const sortParam = TableHelper.sortingStateToParam(sorting, "id,desc");
		const filtersParams = {
			...filters,
			contractingCompanies: filters.contractingCompanies,
			covenantsNumber: filters.covenants
		};
		if ("covenants" in filtersParams) {
			delete filtersParams.covenants;
		}
		return {
			...sortParam,
			...filtersParams
		};
	};

	const getCategories = () => {
		setIsFetchingData(true);

		categoryService
			.listCategoriesPaginated(parseParams())
			.then((response) => {
				setCategories(response.content);
				setTotalPages(response.totalPages);
				setIsFetchingData(false);
			})
			.catch((error) => {
				setCategories([]);
				setTotalPages(0);
				setIsFetchingData(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	useEffect(() => {
		if (initialized) {
			getCategories();
		} else {
			setInitialized(true);
		}
	}, [filters]);

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;
		const newFilters = {
			size: filters.size,
			page: 1,
			...appliedFilters,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};
		updateUrl(newFilters);
	};

	useHandleBack(navigate);

	return (
		<Container className="categories-page">
			<div className="categories-heading">
				<span className="categories-title">Categorias</span>
				<CategoriesListFilters onApply={onApplyFilters} />
				<Button
					kind="default"
					styled="primary"
					cssClass="create-clause-button"
					onClick={() => navigate("/categories/create")}
				>
					<IcoPlus color={colors.neutral["high-pure-50"]} />
					<span>Criar categoria</span>
				</Button>
			</div>
			{isFetchingData ? (
				<Loading type="primary" />
			) : categories.length === 0 ? (
				<EmptyCategoriesList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<CategoriesTable
					content={categories}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
				/>
			)}
		</Container>
	);
}
