import React from "react";
import "./styles.scss";

export type AlertType = "alert" | "error" | "warning";

interface AlertBannerProps {
	title: string;
	message: string;
	icon: React.ReactNode;
	type?: AlertType;
}

function AlertBanner({
	title,
	message,
	icon,
	type = "alert"
}: AlertBannerProps) {
	return (
		<div className={`banner-container ${type}-banner`}>
			<div>{icon}</div>
			<div>
				<div className="title">{title}</div>
				<div className="message">{message}</div>
			</div>
		</div>
	);
}

export default AlertBanner;
