import * as Yup from "yup";

import { ErrorHelper } from "helpers";
import { CodeName, IdName } from "models/types";

import { BaseModel } from "../BaseModel";
import { Covenant } from "./Covenant";
import { Monitoring } from "./Monitoring";
import { ClauseStatus } from "./enums";
import {
	ClauseCreationEditionProps,
	ClauseCreationEditionPropsRequest,
	ClauseProps
} from "./types";

export class Clause extends BaseModel {
	clauseId: string;
	clauseNumber: string;
	description: string;
	startDate: Date;
	status: CodeName;
	frequency: CodeName;
	covenant: Partial<Covenant>;
	clauseCategory: IdName;
	teams: Monitoring[];
	completionDate?: Date;

	constructor(data: ClauseProps) {
		super(data);

		this.clauseId = data.clauseId;
		this.clauseNumber = data.clauseNumber;
		this.description = data.description;
		this.startDate = Clause.getValueOrNew(data.startDate, Date);
		this.status = data.status;
		this.frequency = (data.teams || [])[0]?.frequency;
		this.covenant = Covenant.getValueOrNew(data.covenant);
		this.clauseCategory = data.clauseCategory;
		this.teams = (data.teams || []).map((team) =>
			Monitoring.getValueOrNew(team)
		);
		this.completionDate = data.completionDate;
	}

	get teamsNames(): string {
		return this.teams.map((team) => team.name).join(", ");
	}

	get alreadySettled(): boolean {
		return this.status.code === ClauseStatus.Encerrada.code;
	}

	get asValues(): ClauseCreationEditionProps {
		return {
			...this,
			status: this.status.code,
			frequency: this.frequency.code,
			covenant: this.covenant.covenantId,
			clauseCategory: this.clauseCategory.id as string,
			keyword: this.teams?.[0].keyword,
			monitoringList: this.teams?.map((team) => ({
				teamId: team.id,
				monitoringLevel: team.monitoringLevel.code
			}))
		};
	}

	static get initialValues() {
		return {
			monitoringList: [{ teamId: undefined, monitoringLevel: undefined }]
		} as ClauseCreationEditionProps;
	}

	static get validationSchema() {
		return Yup.object().shape({
			clauseNumber: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			description: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			startDate: Yup.date().required(ErrorHelper.errorMessages.fieldRequired),
			frequency: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			covenant: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			clauseCategory: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			keyword: Yup.string().required(ErrorHelper.errorMessages.fieldRequired),
			monitoringList: Yup.array()
				.of(
					Yup.object().shape({
						teamId: Yup.string().required(
							ErrorHelper.errorMessages.fieldRequired
						),
						monitoringLevel: Yup.number().required(
							ErrorHelper.errorMessages.fieldRequired
						)
					})
				)
				.required(ErrorHelper.errorMessages.fieldRequired)
		});
	}

	static creationEditionPropsToRequest(
		values: ClauseCreationEditionProps
	): ClauseCreationEditionPropsRequest {
		return {
			...values,
			covenant: {
				id: values.covenant as string
			},
			clauseCategory: {
				id: values.clauseCategory as string
			},
			monitoringList: (values.monitoringList || []).map((team) => ({
				teamId: team.teamId as string,
				monitoringLevel: team.monitoringLevel as number
			}))
		};
	}
}
