import { BaseModel } from "../BaseModel";

export interface ContractingCompanyProps {
	id: string;
	name: string;
}

export class ContractingCompany extends BaseModel {
	id: string;
	name: string;

	constructor(data: ContractingCompanyProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
	}
}
