import colors from "colors";
import { IconProps } from "./types";

export default function IcoArrowDown({
	color = colors.neutral["low-pure-500"],
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 2L8 14M8 14L2 8M8 14L14 8"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
