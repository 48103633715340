import { Team } from "models/auth/Team";
import { TeamCreationProps, TeamEditionProps } from "models/auth/types";

import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class TeamService extends BaseServices<Team> {
	constructor() {
		super(Team);
	}

	override async listPaginated(params: any): Promise<BasePageResponse> {
		const pageableParams = {
			...params,
			roles: params.roles?.join(","),
			teams: params.teams?.join(","),
			enabled: true
		};
		return super.listPaginated("teams/paginated", pageableParams);
	}

	async list(params: any): Promise<Team[]> {
		return super.list("teams", params);
	}

	async disable(teamId: string | number) {
		return this.delete(`teams/${teamId}`);
	}

	async editTeam(team: TeamEditionProps, teamId: string | number) {
		return this.put(`teams/${teamId}`, team);
	}

	async getTeamById(id: string | number) {
		try {
			const response = await this.retrieve(`teams/${id}`);
			return response;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	async createTeam(team: TeamCreationProps) {
		return this.create(`teams`, team);
	}
}
