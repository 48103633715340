import { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IcoArrowUp } from "assets/icons";
import { Button, Pagination, Table } from "components";

import { Category } from "models/covenant/Category";

import colors from "colors";
import "./styles.scss";

type CategoryTableProps = {
	content: Category[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
};

type NavigationButtonCellProps = {
	value: number;
	singularText: string;
	pluralText: string;
	destination: string;
};

export default function CategoriesTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting
}: CategoryTableProps) {
	const navigate = useNavigate();
	const [isScrolledDown, setIsScrolledDown] = useState(false);

	const getNavigationButtonCell = (data: NavigationButtonCellProps) => {
		if (!data.value || data.value === 0) {
			return <span>{`0 ${data.singularText}`}</span>;
		}

		return (
			<Button
				cssClass="navigate-button"
				kind="link"
				styled="ghost"
				onClick={() => navigate(`${data.destination}`)}
			>
				{`${data.value} ${
					data.value > 1 ? data.pluralText : data.singularText
				}`}
			</Button>
		);
	};

	const columns: ColumnDef<Category>[] = [
		{
			id: "name",
			accessorKey: "name",
			header: "Categoria",
			meta: {
				columnClass: "name-column"
			}
		},
		{
			id: "covenantCount",
			accessorKey: "covenantCount",
			cell: (row) => {
				const covenantCellInfo: NavigationButtonCellProps = {
					value: row.getValue() as number,
					singularText: "contrato",
					pluralText: "contratos",
					destination: `/covenants?categories=${row.row.original.id}`
				};
				return getNavigationButtonCell(covenantCellInfo);
			},
			header: "Contratos vinculados",
			meta: {
				columnClass: "covenants-column"
			}
		},
		{
			id: "clauseCount",
			accessorKey: "clauseCount",
			cell: (row) => {
				const clauseCellInfo: NavigationButtonCellProps = {
					value: row.getValue() as number,
					singularText: "cláusula",
					pluralText: "cláusulas",
					destination: `/clauses?categories=${row.row.original.id}`
				};
				return getNavigationButtonCell(clauseCellInfo);
			},
			header: "Cláusulas vinculadas",
			meta: {
				columnClass: "clauses-column"
			}
		}
	];

	const checkScrollTop = () => {
		const minPixelScrolled = 300;
		setIsScrolledDown(window.scrollY > minPixelScrolled);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => window.removeEventListener("scroll", checkScrollTop);
	}, []);

	return (
		<div className="categories-table">
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			{isScrolledDown && (
				<Button
					onClick={scrollToTop}
					kind="icon"
					cssClass="scroll-to-top-button"
				>
					<IcoArrowUp color={colors.neutral["high-100"]} />
				</Button>
			)}
		</div>
	);
}
