import { Avatar } from "components";

import { IcoSearch } from "assets/icons";
import colors from "colors";
import "./styles.scss";

import { EmptyListProps } from "./types";

export default function EmptyList({ title, message }: EmptyListProps) {
	return (
		<div className="empty-list-container">
			<div className="empty-list-content">
				<Avatar
					size="xl"
					bgColor={colors.neutral["high-100"]}
					borderStyle="none"
				>
					<IcoSearch size="32" color={colors.neutral["low-pure-500"]} />
				</Avatar>
				<span className="empty-list-title">{title}</span>
				<span className="empty-list-message">{message}</span>
			</div>
		</div>
	);
}
