export default function IcoError() {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
				fill="#E4204E"
			/>
			<path
				d="M28 12L19.7699 20.2265M19.7699 20.2265L12.4518 27.5413M19.7699 20.2265L27.5468 28M19.7699 20.2265L11.9987 12.4587"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
