import logo from "assets/icons/ico-attention.svg";
import { Button } from "components";
import { UnloggedLayout } from "layouts";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import "./styles.scss";

export default function OAuthError() {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.auth);

	const goToLogin = () => {
		navigate("/");
	};

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, []);

	return (
		<UnloggedLayout styles={{ "items-center": true, "error-container": true }}>
			<img
				className="auth-error-attention"
				src={logo}
				alt="Ícone atenção"
				height={56}
				width={56}
			/>
			<span className="oauth-text-body">
				<h5>Email não cadastrado</h5>
				Esse email não tem permissão, solicite acesso ao seu gestor.
			</span>
			<Button
				kind="default"
				styled="primary"
				cssClass="back-to-login-button"
				onClick={goToLogin}
			>
				<span>Voltar ao login</span>
			</Button>
		</UnloggedLayout>
	);
}
