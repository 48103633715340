import { useFormik } from "formik";
import { useEffect } from "react";

import { IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import { Button, DatePicker, Drawer } from "components";

import MultiselectFilter from "components/MultiselectFilter";
import { FrequencyOptions } from "models/covenant/enums";
import { TaskFilterDrawerProps } from "../../types";
import "./styles.scss";

export default function TaskFilterDrawer({
	onApplyFilters,
	isOpen,
	onClickOutside,
	allCovenants,
	allCategories,
	allTeams,
	...filters
}: TaskFilterDrawerProps) {
	const formik = useFormik({
		initialValues: { ...filters },
		onSubmit: (data) => {
			const dataFormat = {
				categories: data.categories || [],
				contractingCompanies: data.teams || [],
				covenants: data.covenants || [],
				frequencies: data.frequencies || [],
				fromDate: data.fromDateValue
					? data.fromDateValue.toISOString().split("T")[0]
					: "",

				teams: data.teams || [],

				untilDate: data.untilDateValue
					? data.untilDateValue.toISOString().split("T")[0]
					: ""
			};

			onApplyFilters(dataFormat);
		},
		onReset: () => {
			formik.setValues({});
			onApplyFilters({});
		}
	});

	const onClose = () => {
		formik.setValues({ ...filters });
		onClickOutside();
	};

	useEffect(() => {
		formik.setFieldValue("fromDate", filters.fromDate);
	}, [filters.fromDate]);

	useEffect(() => {
		formik.setFieldValue("untilDate", filters.untilDate);
	}, [filters.untilDate]);

	useEffect(() => {
		formik.setFieldValue("covenants", filters.covenants);
	}, [filters.covenants]);

	useEffect(() => {
		formik.setFieldValue("categories", filters.categories);
	}, [filters.categories]);

	useEffect(() => {
		formik.setFieldValue("teams", filters.teams);
	}, [filters.teams]);

	useEffect(() => {
		formik.setFieldValue("frequencies", filters.frequencies);
	}, [filters.frequencies]);

	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Filtro">
			<form
				className="tasks-filters-form"
				onSubmit={formik.handleSubmit}
				onReset={formik.handleReset}
			>
				<div className="inputs-section">
					<div className="date-group">
						<DatePicker
							label="De"
							name="fromDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("fromDateValue", date);
							}}
							value={formik.values.fromDateValue}
						/>
						<DatePicker
							label="Até"
							name="untilDate"
							onBlur={formik.handleBlur}
							onChange={(date) => {
								formik.setFieldValue("untilDateValue", date);
							}}
							value={formik.values.untilDateValue}
							min={formik.values.fromDateValue}
						/>
					</div>
					<MultiselectFilter
						allItems={allCovenants}
						valueProperty="id"
						labelProperty="covenantNumber"
						label="Contrato"
						name="covenants"
						formik={formik}
					/>
					<MultiselectFilter
						allItems={allCategories}
						valueProperty="id"
						labelProperty="name"
						label="Categoria"
						name="categories"
						formik={formik}
					/>
					<MultiselectFilter
						allItems={allTeams}
						valueProperty="id"
						labelProperty="name"
						label="Time"
						name="teams"
						formik={formik}
					/>

					<div className="frequency-group">
						<span className="frequency-label">Periodicidade</span>
						<div className="frequency-selector">
							{FrequencyOptions.map((frequency) => (
								<div key={`frequency-check-${frequency.label}`}>
									<input
										id={`frequency-check-${frequency.label}`}
										key={`frequency-checkbox-${frequency.label}`}
										type="checkbox"
										value={frequency.value}
										name="frequencies"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										checked={formik.values.frequencies?.includes(
											frequency.value.toString()
										)}
									/>
									<label
										htmlFor={`frequency-check-${frequency.label}`}
										key={`frequency-check-label-${frequency.label}`}
									>
										{frequency.label}
									</label>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="actions-section">
					<Button
						cssClass="reset-filters"
						kind="default"
						styled="secondary"
						type="reset"
					>
						<IcoTrash color={colors.neutral["low-pure-500"]} />
						Limpar filtro
					</Button>
					<Button
						cssClass="submit-filters"
						kind="default"
						styled="primary"
						type="submit"
					>
						<IcoFilter color={colors.neutral["high-pure-50"]} />
						Filtrar
					</Button>
				</div>
			</form>
		</Drawer>
	);
}
