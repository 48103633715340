import { Button, Container } from "components";

import { HeaderInfoProps } from "./types";

import "./styles.scss";

export default function HeaderInfo({
	icon,
	title,
	subtitle,
	href,
	buttonText
}: HeaderInfoProps) {
	return (
		<div className="container-header-info">
			<Container className="stack--3 items-center text-center mx-auto">
				<div className="icon">{icon && icon}</div>
				<p className="headline-1">{title}</p>
				{subtitle && (
					<p className="headline-4 text-neutral-low-300">{subtitle}</p>
				)}
				{href && buttonText && (
					<a className="mt-8" href={href}>
						<Button kind="default" styled="primary">
							{buttonText}
						</Button>
					</a>
				)}
			</Container>
		</div>
	);
}
