import { useEffect, useState } from "react";

import { ActionDrawer } from "components";
import { ActionDrawerProps } from "components/ActionDrawer";
import ModalCancelConfirm from "components/ModalCancelConfirm";

import "./styles.scss";

type ClauseBatchActionDrawerProps = ActionDrawerProps & {
	clausesQty: number;
	children: React.ReactNode;
	value: number | string[] | string | number[] | undefined;
	setValue: (param: any) => void;
	onConfirm: () => Promise<void> | void;
	error?: boolean;
	countTitle: string;
	modalTitle: string;
	modalInfo: string;
	toastSuccessTitle: string;
	toastSuccessMessage: string;
	kind?: "default" | "warning" | "error" | "success";
};

export default function ClauseBatchActionDrawer({
	isOpen,
	clausesQty,
	children,
	value,
	setValue,
	onConfirm,
	countTitle,
	modalTitle,
	modalInfo,
	toastSuccessTitle,
	toastSuccessMessage,
	kind,
	...props
}: ClauseBatchActionDrawerProps) {
	const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

	const hasNoValue = () =>
		!value || (typeof value === "object" && value.length === 0);

	const batchAction = async () => {
		if (hasNoValue()) {
			setIsOpenConfirmModal(false);
			throw new Error("Selecione um valor");
		}

		try {
			await onConfirm();

			setIsOpenConfirmModal(false);
		} catch (err) {
			setIsOpenConfirmModal(false);
			throw err;
		}
	};

	useEffect(
		() => setValue(typeof value === "object" ? [] : undefined),
		[isOpen]
	);

	return (
		<>
			<ActionDrawer
				isOpen={isOpen}
				{...props}
				onConfirm={() => setIsOpenConfirmModal(true)}
				confirmButtonDisabled={hasNoValue()}
			>
				<div className="count-title">{countTitle}</div>
				<div className="quantity">
					<div className="quantity-label">Quantidade</div>
					<div className="quantity-value">{clausesQty}</div>
				</div>
				<div>{children}</div>
			</ActionDrawer>
			<ModalCancelConfirm
				modalTitle={modalTitle}
				modalInfo={modalInfo}
				isOpen={isOpenConfirmModal}
				onClose={() => setIsOpenConfirmModal(false)}
				onConfirm={batchAction}
				toastSuccessTitle={toastSuccessTitle}
				toastSuccessMessage={toastSuccessMessage}
				kind={kind}
			/>
		</>
	);
}
