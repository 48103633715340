import {
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import {
	IcoArrowDown,
	IcoArrowDownDS,
	IcoArrowUPDS,
	IcoArrowUp,
	IcoGrabber,
	IcoReverter
} from "assets/icons";
import SelectionFooter from "components/SelectionFooter";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { ColumnMetaProps, TableProps } from "./types";

function IndeterminateCheckbox({
	indeterminate,
	...rest
}: {
	indeterminate: any;
	checked: boolean;
}) {
	const ref = useRef(null);

	useEffect(() => {
		if (typeof indeterminate === "boolean") {
			(ref.current! as any).indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return <input type="checkbox" ref={ref} {...rest} />;
}

export default function Table({
	cssClass,
	columns,
	data,
	sorting,
	setSorting,
	rowSelection,
	setRowSelection,
	setSelectedRowModel,
	selectionActions,
	selectionLabel,
	canExpand,
	getSubRows,
	getRowCanExpand,
	getSubRowComponent
}: TableProps<any>) {
	const [expanded, setExpanded] = useState<ExpandedState>({});

	const mountExpanderColumn = () => {
		return [
			{
				id: "expander",
				header: ({ table }: any) => (
					<div onClick={table.getToggleAllRowsExpandedHandler()}>
						{table.getIsAllRowsExpanded() ? (
							<IcoArrowUPDS />
						) : (
							<IcoArrowDownDS />
						)}
					</div>
				),
				cell: ({ row }: any) => {
					return row.getCanExpand() ? (
						<div onClick={row.getToggleExpandedHandler()}>
							{row.getIsExpanded() ? <IcoArrowUPDS /> : <IcoArrowDownDS />}
						</div>
					) : (
						<IcoGrabber />
					);
				},
				meta: {
					columnClass: "expander-column"
				}
			}
		];
	};

	const getAllSelectableRows = (table: any) => {
		const { rows } = table.getCoreRowModel();
		return rows.filter((row: any) => !row.parentId && row.getCanSelect());
	};

	const getIsAllRowsSelected = (table: any) => {
		const rows = getAllSelectableRows(table);
		return rows.every((row: any) => row.getIsSelected());
	};

	const selectAllRows = (table: any) => {
		const rows = getAllSelectableRows(table);
		const currentState = getIsAllRowsSelected(table);
		rows.forEach((row: any) => {
			row.toggleSelected(!currentState);
		});
	};

	const mountSelecterColumn = () => {
		return [
			{
				id: "select",
				header: ({ table }: any) => (
					<IndeterminateCheckbox
						{...{
							checked: getIsAllRowsSelected(table),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: () => selectAllRows(table)
						}}
					/>
				),
				cell: ({ row }: any) => (
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler()
						}}
					/>
				),
				meta: {
					columnClass: "select-column"
				}
			}
		];
	};

	const {
		getHeaderGroups,
		getRowModel,
		getFooterGroups,
		getSelectedRowModel,
		getAllColumns
	} = useReactTable({
		data,
		columns: [
			...(canExpand === "start" ? mountExpanderColumn() : []),
			...(rowSelection && setRowSelection ? mountSelecterColumn() : []),
			...columns,
			...(canExpand === "end" ? mountExpanderColumn() : [])
		],
		getCoreRowModel: getCoreRowModel(),
		...(setSorting && { onSortingChange: setSorting }),
		getSortedRowModel: getSortedRowModel(),
		manualSorting: true,
		enableRowSelection: !!(rowSelection && setRowSelection),
		enableMultiRowSelection: !!(rowSelection && setRowSelection),
		enableSubRowSelection: false,
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
			...(sorting && { sorting }),
			...(canExpand && { expanded })
		},
		onExpandedChange: setExpanded,
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			const subrows = getSubRows ? getSubRows(row) : [];
			return subrows.map((subrow: any) => ({
				...subrow,
				isChildOfExpanded: true
			}));
		},
		getRowCanExpand
	});

	useEffect(() => {
		if (setSelectedRowModel) setSelectedRowModel(getSelectedRowModel());
	}, [rowSelection]);

	return (
		<>
			<table className={cssClass}>
				<thead>
					{getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th
									key={header.id}
									className={
										`${
											(header.column.columnDef.meta as ColumnMetaProps)
												?.columnClass || ""
										}` +
										` ${
											(header.column.columnDef.meta as ColumnMetaProps)
												?.headerClass || ""
										} ${
											header.column.getCanSort()
												? "cursor--pointer select-none"
												: ""
										}`
									}
									onClick={(e) => {
										const sortingHandler =
											header.column.getToggleSortingHandler();
										if (sortingHandler) sortingHandler(e);
									}}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext()
										  )}
									{header.column.getCanSort() && (
										<span className="sort-icon">
											{{
												asc: <IcoArrowUp size="16px" />,
												desc: <IcoArrowDown size="16px" />
											}[header.column.getIsSorted() as string] ?? (
												<IcoReverter size="16px" />
											)}
										</span>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{data.length ? (
						getRowModel().rows.map((row) =>
							!row.original.isChildOfExpanded ? (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<td
											key={cell.id}
											className={
												`${
													(cell.column.columnDef.meta as ColumnMetaProps)
														?.columnClass || ""
												}` +
												` ${
													(cell.column.columnDef.meta as ColumnMetaProps)
														?.cellClass || ""
												}`
											}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</tr>
							) : getSubRowComponent ? (
								getSubRowComponent(row, getAllColumns())
							) : (
								<tr>
									<td colSpan={getAllColumns().length}>
										Não foi possível exibir os dados
									</td>
								</tr>
							)
						)
					) : (
						<tr>
							<td colSpan={columns.length}>Nenhum dado para exibir</td>
						</tr>
					)}
				</tbody>
				<tfoot>
					{getFooterGroups().map((footerGroup) => (
						<tr key={footerGroup.id}>
							{footerGroup.headers.map((header) => (
								<th
									key={header.id}
									className={
										`${
											(header.column.columnDef.meta as ColumnMetaProps)
												?.columnClass || ""
										}` +
										` ${
											(header.column.columnDef.meta as ColumnMetaProps)
												?.footerClass || ""
										}`
									}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.footer,
												header.getContext()
										  )}
								</th>
							))}
						</tr>
					))}
				</tfoot>
			</table>
			{rowSelection && setRowSelection && (
				<SelectionFooter
					open={Object.keys(rowSelection).length > 0}
					label={selectionLabel ? selectionLabel() : undefined}
				>
					{selectionActions}
				</SelectionFooter>
			)}
		</>
	);
}
