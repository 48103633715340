import { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import { IcoFile, IcoTrash } from "assets/icons";
import colors from "colors";
import {
	Avatar,
	Button,
	Pagination,
	Table,
	DeletionModal as UserDeletionModal
} from "components";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { Team } from "models/auth/Team";
import { User } from "models/auth/User";
import { UserService } from "services/users";
import "./styles.scss";

// import UserDeletionModal from "./DeletionModal";
import { useQuerystringDetails } from "hooks/querystringFilter";
import UserDetailsDrawer from "./DetailsDrawer";

type UserTableProps = {
	content: any[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	setFilters: (value: any) => void;
};

const userListLimit = 4;
let remainderAvatarCounter = 0;

function getTeamsPresentation(teams?: Team[] | undefined) {
	if (!teams || teams.length === 0) {
		return "Nenhum time vinculado";
	}

	remainderAvatarCounter++;

	const remainder = teams.length - userListLimit;
	const remainderTeams: string[] = [];

	return (
		<div className="teams-row">
			{teams.map((team, idx) => {
				if (idx >= userListLimit) {
					remainderTeams.push(team.name);
					return null;
				}

				const text = team.name
					.match(/(?<!\p{L}\p{M}*)\p{L}\p{M}*/gu)
					?.slice(0, 1)
					.join("")
					.toUpperCase();
				return (
					<div key={`user-table-${idx}`}>
						<Tooltip anchorSelect={`#avatar--${team.id}`} place="top">
							{team.name}
						</Tooltip>
						<Avatar
							key={`team-${team.id}`}
							cssClass={`team-avatar--${idx}`}
							id={`avatar--${team.id}`}
							borderWidth={1}
						>
							{text}
						</Avatar>
					</div>
				);
			})}
			{remainder >= 1 && (
				<div>
					<Tooltip
						anchorSelect={`#remainder-avatar--${remainderAvatarCounter}`}
						place="top"
					>
						{`${remainderTeams.join("; ")}.`}
					</Tooltip>
					<Avatar
						key="team-remainder"
						bgColor={colors.neutral["low-pure-500"]}
						color="white"
						borderStyle="none"
						cssClass={`team-avatar--${userListLimit} team-avatar-remainder`}
						id={`remainder-avatar--${remainderAvatarCounter}`}
					>
						{`+${remainder}`}
					</Avatar>
				</div>
			)}
		</div>
	);
}

export default function UsersTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting,
	setFilters
}: UserTableProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [userForDeletion, setUserForDeletion] = useState<User>();
	const {
		itemForInspection,
		getItemForInspectionFromUrl,
		setItemForInspectionInUrl
	} = useQuerystringDetails(content, "id", setFilters);

	const columns: ColumnDef<User>[] = [
		{
			accessorKey: "name",
			header: () => <span>Nome</span>,
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "name-column"
			}
		},
		{
			accessorKey: "role",
			enableSorting: false,
			header: () => <span>Perfil</span>,
			meta: {
				columnClass: "role-column"
			}
		},
		{
			id: "teams",
			accessorKey: "teams",
			header: () => <span>Time</span>,
			enableSorting: false,
			cell: (row) => {
				return getTeamsPresentation(row.getValue() as Team[] | undefined);
			},
			meta: {
				columnClass: "team-column"
			}
		},
		{
			accessorKey: "email",
			header: () => <span>Email</span>,
			meta: {
				columnClass: "email-column"
			}
		},
		{
			id: "enabledOn",
			accessorFn: (row) =>
				`${row.enabled ? "Ativo" : "Inativo"} desde ${new Date(
					row.enabledOn
				).toLocaleDateString()}`,
			header: () => <span>Status</span>,
			meta: {
				columnClass: "enabledOn-column"
			}
		},
		{
			id: "action",
			header: "Ações",
			enableSorting: false,
			cell: ({ row }) => (
				<span>
					{/* TODO: Fazer ação do botão -> redirecionar para tela de edição (deixado para issue de edição) */}
					<Tooltip anchorSelect=".detail-button" place="top">
						Detalhes
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => setItemForInspectionInUrl(row.original)}
						cssClass="detail-button"
					>
						<IcoFile size="1.75rem" />
					</Button>
					<Tooltip anchorSelect=".deletion-button" place="top">
						Remover Usuário
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => {
							setUserForDeletion(row.original);
							setIsModalOpen(true);
						}}
						cssClass="deletion-button"
					>
						<IcoTrash size="1.75rem" />
					</Button>
				</span>
			),
			meta: {
				columnClass: "action-column"
			}
		}
	];

	const closeDetailsDrawer = () => {
		setItemForInspectionInUrl(undefined);
	};

	const closeModal = () => {
		setUserForDeletion(undefined);
		setIsModalOpen(false);
	};

	const confirmModalAction = () => {
		setUserForDeletion(undefined);
		setIsModalOpen(false);
		onPageChange(currentPage, pageSize);
	};

	useEffect(() => getItemForInspectionFromUrl(), []);

	return (
		<>
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<UserDetailsDrawer
				isOpen={!!itemForInspection}
				onClose={closeDetailsDrawer}
				user={itemForInspection || null}
			/>
			<UserDeletionModal
				itemForDeletion={userForDeletion}
				itemForService={UserService}
				confirMessage="O acesso do usuário foi removido"
				modalTitle="Excluir usuário"
				modalInfo="Ao excluir, todas as informações serão deletadas permanentemente do sistema. Não haverá possibilidade de reverter esta ação."
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
			/>
		</>
	);
}
