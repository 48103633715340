import { IconProps } from "./types";

export default function IcoSignalCheck({ size = "32" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" rx="16" fill="#E5FBF6" />
			<path
				d="M8.80005 16.0002L14.32 20.8002L23.2 11.2002"
				stroke="#1A2F2A"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
