export default function IcoPlusSecondary() {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.5 12L3.5 12M12.5 21L12.5 3"
				stroke="#1D1D1D"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
