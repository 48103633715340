import clsx from "clsx";

import colors from "colors";
import { AvatarProps } from "./types";

import "./styles.scss";

export default function Avatar({
	rounded = true,
	color = colors.neutral["low-pure-500"],
	bgColor = colors.neutral["high-pure-50"],
	borderColor = colors.neutral["200"],
	borderStyle = "solid",
	borderWidth = 2.5, // valor em px
	size = "md",
	children,
	id,
	cssClass
}: AvatarProps) {
	return (
		<div
			className={clsx(`avatar ${cssClass || ""} outline-neutral-low-pure-500`, {
				"avatar-rounded": rounded,
				"avatar-squared": !rounded,
				"avatar-4": size === "sm",
				"avatar-6": size === "md",
				"avatar-10": size === "lg",
				"avatar-14": size === "xl"
			})}
			style={{
				backgroundColor: bgColor,
				borderStyle,
				borderColor,
				borderWidth: `${borderWidth / 16}rem`,
				color
			}}
			id={id}
		>
			{children}
		</div>
	);
}
