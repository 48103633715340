import { ErrorHelper } from "helpers";
import * as Yup from "yup";
import { BaseModel } from "../BaseModel";
import { TeamCreationProps, TeamProps } from "./types";

export class Team extends BaseModel {
	id: number;
	name: string;
	users: [];
	createdOn: string;
	clauseCount: number;

	constructor(data: TeamProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.users = data.users;
		this.createdOn = data.createdOn;
		this.clauseCount = data.clauseCount;
	}

	get clauseCountLabel() {
		const { clauseCount } = this;
		return clauseCount > 0
			? `${clauseCount} cláusula${clauseCount !== 1 ? "s" : ""} vinculada${
					clauseCount !== 1 ? "s" : ""
			  }`
			: "Sem cláusulas associadas";
	}

	static get initialValues() {
		// Ajustar para create edit em issue de refatoração
		return {
			name: "",
			userId: []
		} as TeamCreationProps;
	}

	static get validationSchema() {
		return Yup.object().shape({
			name: Yup.string().required(ErrorHelper.errorMessages.fieldRequired)
		});
	}
}
