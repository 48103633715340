import { BaseModel } from "../BaseModel";
import { TeamProps } from "./types";

export class Role extends BaseModel {
	name: string;

	constructor(data: TeamProps) {
		super(data);
		this.name = data.name;
	}
}
