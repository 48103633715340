import { IcoClose } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";

import "./styles.scss";
import { DrawerProps } from "./types";

export default function Drawer({
	title,
	direction = "right",
	isOpen,
	children,
	onClose = () => {},
	cssClass
}: DrawerProps) {
	return (
		<div
			className={clsx("drawer-wrapper", {
				open: isOpen
			})}
			onClick={onClose}
		>
			<div
				className={clsx("drawer", {
					open: isOpen,
					[direction]: true,
					[cssClass as string]: !!cssClass
				})}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="wrapper-heading">
					<span>{title}</span>
					<Button
						kind="icon"
						styled="ghost"
						cssClass="close-drawer-button"
						onClick={onClose}
					>
						<IcoClose size="2rem" />
					</Button>
				</div>
				<div className="drawer-divider" />
				<div className="content">{children}</div>
			</div>
		</div>
	);
}
