import { useState } from "react";

import { Button } from "components";
import { ClauseBatchActionDrawer } from "features";

import { FrequencyOptions } from "models/covenant/enums";
import { ClauseService } from "services/clauses";

import "./styles.scss";

type ChangeFrequencyDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	clausesIds: string[];
	reloadTable: () => void;
};

export default function ChangeFrequencyDrawer({
	isOpen,
	onClose,
	clausesIds,
	reloadTable
}: ChangeFrequencyDrawerProps) {
	const clauseService = new ClauseService();
	const [frequencySelected, setFrequencySelected] = useState<number>();

	const changeFrequency = async () => {
		await clauseService.changeFrequency({
			clausesIds,
			frequency: frequencySelected!
		});

		onClose();
		reloadTable();
	};

	return (
		<ClauseBatchActionDrawer
			isOpen={isOpen}
			onClose={onClose}
			clausesQty={clausesIds.length}
			value={frequencySelected}
			setValue={setFrequencySelected}
			title="Alterar periodicidade"
			alertTitle="Periodicidade"
			alertMessage="Será alterada a periodicidade de todas as cláusulas selecionadas"
			onConfirm={changeFrequency}
			countTitle="Cláusulas selecionadas"
			modalTitle="Alterar periodicidade"
			modalInfo="Ao confirmar, todas as cláusulas selecionadas sofrerão alteração na periodicidade"
			toastSuccessTitle="Periodicidade alterada"
			toastSuccessMessage="A periodicidade das cláusulas selecionadas foram alteradas"
		>
			<div>Alterar periodicidade</div>
			<div className="frequency-selector">
				{FrequencyOptions.map((frequency) => (
					<div
						key={`frequency-check-${frequency.label}`}
						className="btn-frequency"
					>
						<Button
							kind="chip"
							styled={
								frequencySelected === frequency.value ? "primary" : "secondary"
							}
							onClick={() => setFrequencySelected(frequency.value)}
						>
							{frequency.label}
						</Button>
					</div>
				))}
			</div>
		</ClauseBatchActionDrawer>
	);
}
