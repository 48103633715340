import { HeaderInfo } from "components";

import { IcoHeaderInfoWarning } from "assets/icons";

export default function Page404() {
	return (
		<HeaderInfo
			buttonText="Conferir"
			href="/"
			icon={<IcoHeaderInfoWarning />}
			subtitle="Parece que essa página foi removida ou não existe"
			title="Página não encontrada"
		/>
	);
}
