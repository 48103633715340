import { IconProps } from "./types";

export default function IcoInfoCircle({
	color = "#1D1D1D",
	size = "32"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="info">
				<circle id="Ellipse" cx="16" cy="16" r="12" fill={color} />
				<path
					id="Line"
					d="M16 14.5L16 22"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<circle id="Ellipse_2" cx="16" cy="10" r="1.5" fill="white" />
			</g>
		</svg>
	);
}
