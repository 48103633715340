import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";

const useMock = (axios: AxiosInstance) => {
	const mock = new MockAdapter(axios);

	mock.onPost("search/").reply(200, {
		count: 3,
		total_pages: 1,
		results: []
	});
};

export default useMock;
