import {
	IcoCheckFilled,
	IcoClip,
	IcoCloseFilled,
	IcoPlus,
	IcoRefresh,
	IcoTrash
} from "assets/icons";
import IcoFileSecondary from "assets/icons/ico-file-secondary";
import colors from "colors";
import { InputHTMLAttributes } from "react";
import "./styles.scss";

type InputFileProps = InputHTMLAttributes<HTMLInputElement> & {
	// label: string;
	title: string;
	description: string;
	file?: any;
	isEditionPage?: boolean;
	error?: string | Array<string>;
	className?: string;
	onClickRemoveFile: () => void;
	onClickRetry: () => void;
	successMessage?: string;
};

export default function InputFile({
	title,
	description,
	id,
	name,
	file,
	isEditionPage,
	accept,
	multiple,
	required,
	disabled,
	error,
	onChange,
	className = "",
	onClickRemoveFile,
	onClickRetry,
	successMessage = ""
}: InputFileProps) {
	return (
		<label htmlFor={id} className="w-full">
			<input
				id={id}
				type="file"
				name={name}
				onChange={onChange}
				disabled={disabled}
				required={required}
				accept={accept}
				multiple={multiple}
				hidden
			/>
			<div
				className={`input-file-container ${className} ${
					disabled ? "" : "cursor--pointer"
				}`}
			>
				<div className="content-container">
					<div className="file-info-container">
						{file ? (
							error ? (
								<IcoCloseFilled />
							) : (
								<IcoCheckFilled />
							)
						) : isEditionPage ? (
							<IcoFileSecondary />
						) : (
							<IcoClip />
						)}
						<div className="text-container">
							<p className="input-file-title">{title}</p>
						</div>
					</div>
					{file ? (
						<div
							className={`action-button ${disabled ? "" : "cursor--pointer"}`}
							onClick={(e) => {
								e.preventDefault();
								if (error) {
									onClickRetry();
								} else {
									onClickRemoveFile();
								}
								// error ? onClickRetry() : ;
							}}
						>
							{error ? (
								<IcoRefresh />
							) : (
								<IcoTrash color={colors.neutral["low-pure-500"]} />
							)}
						</div>
					) : (
						<IcoPlus />
					)}
				</div>
				<hr className="hr-input-file" />

				<div className="feedback-container">
					<div className="div-input-description">
						<p className="input-file-description">{description}</p>
						{file && (
							<hr
								className={`feedback-bar ${error ? "error" : "success"}-bar`}
							/>
						)}
					</div>
					{file && (
						<div>
							<p className="input-file-feedback-message">
								{error
									? Array.isArray(error)
										? error.join("\n")
										: error
									: successMessage}
							</p>
							<p className="input-file-feedback-message complete">
								{successMessage && "100%"}
								{error && "0%"}
							</p>
						</div>
					)}
				</div>
			</div>
			{error && <div className="error-message">{error}</div>}
		</label>
	);
}
