import { IcoSignalCheck } from "assets/icons";
import { Button } from "components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import "./styles.scss";

export default function CovenantCreationSuccess() {
	const navigate = useNavigate();
	const { covenantCreated } = useSelector((state: RootState) => state.covenant);

	return (
		<div className="covenant-creation-success stack--4">
			<IcoSignalCheck />
			<div className="stack--3">
				<div className="title">Contrato adicionado</div>
				<div className="description">
					Foram adicionadas {covenantCreated?.clauses?.length} cláusulas
					vinculadas ao contrato {covenantCreated?.covenantNumber}
				</div>
			</div>
			<div className="stack--3">
				{/* TODO: navigate pra tela de cláusulas com filtro */}
				<Button
					kind="default"
					styled="primary"
					onClick={() =>
						navigate(`/clauses?covenants=${covenantCreated?.covenantId}`)
					}
				>
					Ver todas as cláusulas
				</Button>
				<Button
					kind="default"
					styled="secondary"
					onClick={() => navigate("/covenants")}
				>
					Fechar
				</Button>
			</div>
		</div>
	);
}
