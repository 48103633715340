import logo from "assets/images/logo-ccr.svg";
import clsx from "clsx";
import { Container } from "components";
import React from "react";
import "./styles.scss";

type UnloggedLayoutProps = {
	children: React.ReactNode;
	styles?: { [key: string]: any };
};
export default function UnloggedLayout({
	children,
	styles
}: UnloggedLayoutProps) {
	return (
		<>
			<img
				className="logo-ccr-unlogged"
				src={logo}
				alt="Logo CCR"
				height={80}
				width={66.5}
			/>
			<Container className={clsx("unlogged-container", styles)}>
				{children}
			</Container>
		</>
	);
}
