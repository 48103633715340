import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Header } from "components";
import { useSelector } from "react-redux";
import { RootState } from "store";

export default function LoggedLayout() {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.auth);

	const verifyLogin = () => {
		if (!user) {
			navigate("/");
		}
		console.dir(user);
	};

	useEffect(verifyLogin, []);

	return (
		<>
			<Header account={user} />
			<Outlet />
		</>
	);
}
