import { BrowserRouter as Router } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { GoogleAnalytics4 } from "components";

import "App.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import Routers from "router";

import pjson from "../package.json";

export function App() {
	console.log(pjson.version);

	return (
		<Router>
			<GoogleAnalytics4 />
			<ToastContainer
				autoClose={5000}
				position="top-right"
				icon={false}
				closeButton={false}
				theme="dark"
				transition={Slide}
			/>
			<Routers />
		</Router>
	);
}
