import { IconProps } from "./types";

export default function IcoSignalError({
	size = "32",
	color = "white"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" rx="16" fill="#F83446" />
			<path
				d="M22.3999 9.59961L9.96136 22.0327"
				stroke={color}
				strokeWidth="0.8"
				strokeLinecap="round"
			/>
			<path
				d="M22.0376 22.4004L9.59905 9.96731"
				stroke={color}
				strokeWidth="0.8"
				strokeLinecap="round"
			/>
		</svg>
	);
}
