import { IcoArrowDown } from "assets/icons";
import ReactSelect, {
	Props as ReactSelectProps,
	components
} from "react-select";
import "./styles.scss";

interface SelectProps extends ReactSelectProps {
	label: string;
	error?: string | number | undefined;
}

function DropdownIndicator(props: any): any {
	return (
		<components.DropdownIndicator {...props}>
			<IcoArrowDown />
		</components.DropdownIndicator>
	);
}

export default function Select({
	label,
	options,
	onChange,
	value,
	placeholder,
	className,
	onInputChange,
	error,
	isSearchable,
	...args
}: SelectProps) {
	return (
		<div className="select-container">
			{label && (
				<div
					className={
						args.isDisabled
							? "disabled-label body-1 select-label"
							: "body-1 select-label"
					}
				>
					{label}
				</div>
			)}
			<ReactSelect
				isSearchable={isSearchable}
				options={options}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				onInputChange={onInputChange}
				className={`select-component ${className || ""}`}
				components={{ DropdownIndicator, IndicatorSeparator: () => null }}
				classNamePrefix="select"
				{...args}
			/>
			{error && <div className="error-message">{error}</div>}
		</div>
	);
}
