import clsx from "clsx";
import { ReactNode } from "react";
import "./styles.scss";

type SelectionFooterProps = {
	open: boolean;
	label: ReactNode;
	children: ReactNode;
};

export default function SelectionFooter({
	open,
	label,
	children
}: SelectionFooterProps) {
	return (
		<div
			className={clsx("selection-footer", {
				"selection-footer-open": open === true
			})}
		>
			<div>{label}</div>
			<div className="children">{children}</div>
		</div>
	);
}
