import { ObjectWithAnyValues } from "utils/types";
import { BaseModel } from "../BaseModel";
import { Team } from "./Team";
import { UserProps } from "./types";

export class User extends BaseModel {
	id: number;
	name: string;
	email: string;
	enabled: boolean;
	enabledOn: string;
	lastLoginDate: string;
	role: string;
	teams?: Team[];

	constructor(data: UserProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.email = data.email;
		this.enabled = data.enabled;
		this.enabledOn = data.enabledOn;
		this.lastLoginDate = data.lastLoginDate;

		this.role = data.role;
		this.teams = data.teams?.map((team) => Team.getValueOrNew(team));
	}

	static createFromResponse(response: ObjectWithAnyValues, Model: any = this) {
		if (!response) {
			return response;
		}

		return new Model({ ...response });
	}

	isMaster() {
		return this.role === "ADMIN";
	}

	isAnalyst() {
		return this.role === "ANALYST";
	}

	isManager() {
		return this.role === "MANAGER";
	}
}
