import { addMinutes, format, parse } from "date-fns";

const DateTimeHelper = {
	formatDate(date: Date) {
		return format(date, "dd/MM/yyyy '-' HH:mm:ss");
	},

	isValidDate(date: Date) {
		return date instanceof Date && !Number.isNaN(date.getTime());
	},

	addMinutesToTime(
		time: Date | string,
		minutes: number,
		targetFormat = "HH:mm"
	) {
		return String(
			format(
				addMinutes(parse(String(time), "HH:mm:ss", new Date()), minutes),
				targetFormat
			)
		);
	}
};

export default DateTimeHelper;
