import { IcoArrowLeft, IcoClose, IcoPlus } from "assets/icons";
import colors from "colors";
import { Button, DatePicker, Input, Select, TextArea } from "components";
import "./styles.scss";

import { useFormik } from "formik";
import { Clause } from "models/covenant/Clause";
import {
	ClauseStatus,
	ClauseStatusOptions,
	FrequencyOptions,
	MonitoringLevelOptions
} from "models/covenant/enums";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ClauseService } from "services/clauses";

import { useDispatch } from "react-redux";

import ModalCancelConfirm from "components/ModalCancelConfirm";
import { ValueType } from "components/Multiselect/types";
import ToastContent from "components/ToastContent";
import { SelectHelper } from "helpers/SelectHelper";
import { ClauseCreationEditionProps } from "models/covenant/types";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryService } from "services/categories";
import { CovenantService } from "services/covenants";
import { TeamService } from "services/teams";
import { setClauseCreated, setClauseEdited } from "store/features/clause/slice";

export default function ClauseCreateEditPage() {
	const { id } = useParams();
	const isEdit = !!id;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const clauseService = new ClauseService();
	const categoryService = new CategoryService();
	const covenantService = new CovenantService();
	const teamService = new TeamService();
	const [initialValues, setInitialValues] =
		useState<ClauseCreationEditionProps>(Clause.initialValues);
	const [categories, setCategories] = useState<ValueType[]>([]);
	const [covenants, setCovenants] = useState<ValueType[]>([]);
	const [teams, setTeams] = useState<ValueType[]>([]);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [hasErrorsOnSubmit, sethasErrorsOnSubmit] = useState(false);
	const [fieldsDisabled, setFieldsDisabled] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	async function handleEditClause(values: ClauseCreationEditionProps) {
		if (!isEdit) return;
		setIsLoading(true);

		try {
			const resp = await clauseService.editClause(
				Clause.creationEditionPropsToRequest(values),
				id
			);
			toast.dark(
				<ToastContent
					type="success"
					title="Cláusula alterada"
					subtitle="As informações da cláusula foram alteradas"
					onClose={() => toast.dismiss()}
				/>,
				{
					position: "top-center",
					autoClose: 3000,
					closeOnClick: false,
					progressClassName: "confirmation-toast-success-progress",
					className: "confirmation-toast",
					bodyClassName: "confirmation-toast-body"
				}
			);
			dispatch(setClauseEdited(resp));
			setIsLoading(false);
			navigate("/clauses");
		} catch (error) {
			toast.error(
				<ToastContent
					type="error"
					title="Erro"
					subtitleError={error}
					onClose={() => toast.dismiss()}
				/>
			);
		}
	}

	function createClause(values: ClauseCreationEditionProps) {
		if (isEdit) return;
		setIsLoading(true);

		clauseService
			.createClause(Clause.creationEditionPropsToRequest(values))
			.then((resp) => {
				dispatch(setClauseCreated(resp));
				setIsLoading(false);
				navigate("/clauses");
				toast.dark(
					<ToastContent
						type="success"
						title="Cláusula criada"
						subtitle="Nova cláusula adicionada"
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
			})

			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			);
	}

	const formik = useFormik({
		initialValues,
		validationSchema: Clause.validationSchema,
		onSubmit: (values) => {
			const valuesFormat = {
				startDate:
					values.startDate instanceof Date
						? values.startDate.toISOString().split("T")[0]
						: undefined,
				...values
			};
			if (isEdit) {
				handleEditClause(valuesFormat);
			} else {
				createClause(valuesFormat);
			}
		}
	});

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			sethasErrorsOnSubmit(true);
		} else {
			formik.handleSubmit();
		}
	};

	const getCovenants = () => {
		covenantService
			.listCovenants({})
			.then((resp) =>
				setCovenants(SelectHelper.toValueLabel(resp, "id", "covenantNumber"))
			)
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			);
	};

	const getCategories = () => {
		categoryService
			.listCategories({})
			.then((resp) =>
				setCategories(SelectHelper.toValueLabel(resp, "id", "name"))
			)
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			);
	};

	const getTeams = () => {
		teamService
			.list({})
			.then((resp) => setTeams(SelectHelper.toValueLabel(resp, "id", "name")))
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			);
	};

	useEffect(() => {
		getCategories();
		getCovenants();
		getTeams();
	}, []);

	useEffect(() => {
		if (!id) return;

		clauseService
			.retrieve(id)
			.then((clause) => {
				if (clause.status.code === ClauseStatus.Encerrada.code) {
					setFieldsDisabled(true);
				}
				const newValues = {
					...formik.values,
					...clause.asValues
				};

				setInitialValues({
					...newValues
				});
				formik.setValues(() => ({
					...newValues
				}));
			})
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			);
	}, [id]);

	const closeModal = () => {
		setIsCancelModalOpen(false);
		navigate("/clauses");
	};

	const confirmModalAction = () => {
		if (formik.isValid) {
			formik.submitForm();
			setIsCancelModalOpen(false);
			return true;
		}
		setIsCancelModalOpen(false);
		return false;
	};

	const getTeamValue = (index: number) => {
		if (
			!formik.values.monitoringList ||
			formik.values.monitoringList?.length <= index
		)
			return undefined;

		return teams.find(
			(team) => team.value === formik.values.monitoringList![index]?.teamId
		);
	};

	const getNotificationValue = (index: number) => {
		if (
			!formik.values.monitoringList ||
			formik.values.monitoringList?.length <= index
		)
			return undefined;

		return MonitoringLevelOptions.find(
			(monitoringLevel) =>
				monitoringLevel.value ===
				formik.values.monitoringList![index]?.monitoringLevel
		);
	};

	const onChangeTeamNotification = (
		index: number,
		teamId: string | number | undefined,
		notificationLevel: string | number | undefined
	) => {
		const updatedTeams = JSON.parse(
			JSON.stringify(formik.values.monitoringList || [])
		);
		updatedTeams[index] = {
			teamId,
			monitoringLevel: notificationLevel
		};
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	const onChangeTeams = (selected: any, index: number) => {
		const currentNotification =
			formik.values.monitoringList?.[index]?.monitoringLevel;
		onChangeTeamNotification(index, selected.value, currentNotification);
	};

	const onChangeNotification = (selected: any, index: number) => {
		const currentTeamId = formik.values.monitoringList?.[index]?.teamId;
		onChangeTeamNotification(index, currentTeamId, selected.value);
	};

	const isTeamOptionDisabled = (option: any, index: number) => {
		const alreadySelected = (formik.values.monitoringList || [])
			.map((team) => team.teamId)
			.includes(option.value);
		return alreadySelected && getTeamValue(index)?.value !== option.value;
	};

	const addTeam = () => {
		const updatedTeams = formik.values.monitoringList || [];
		updatedTeams.push({
			teamId: undefined,
			monitoringLevel: undefined
		});
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	const removeTeam = (index: number) => {
		const updatedTeams = formik.values.monitoringList || [];
		updatedTeams.splice(index, 1);
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	function getTeamFieldError(
		index: number,
		fieldName: "teamId" | "monitoringLevel"
	): string | number | undefined {
		if (
			!formik.errors.monitoringList ||
			!Array.isArray(formik.errors.monitoringList)
		) {
			return undefined;
		}

		const teamError = formik.errors.monitoringList[index] as
			| { teamId?: string; monitoringLevel?: number }
			| undefined;
		return teamError && teamError[fieldName] ? teamError[fieldName] : undefined;
	}

	function hasTeamFieldError(
		index: number,
		fieldName: "teamId" | "monitoringLevel"
	): boolean {
		if (
			!formik.errors.monitoringList ||
			!Array.isArray(formik.errors.monitoringList)
		) {
			return false;
		}

		const teamError = formik.errors.monitoringList[index] as
			| { teamId?: string; monitoringLevel?: number }
			| undefined;
		return !!teamError && !!teamError[fieldName];
	}

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(initialValues);

	return (
		<form className="clause-creation-page">
			<div className="clause-creation-header">
				<div className="flex--row flex-items--center">
					<Button
						kind="icon"
						styled="ghost"
						cssClass="left-arrow"
						onClick={() =>
							hasChanges() ? setIsCancelModalOpen(true) : navigate("/clauses")
						}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="clause-creation-title">
						{isEdit ? "Editar cláusula" : "Criar cláusula"}
					</span>
				</div>
			</div>
			<div className="clause-creation-fields-group stack--6">
				<div className="clause-creation-field-title">Cláusula</div>
				<div className="clause-creation-field-container">
					<Input
						label="Número da cláusula"
						placeholder="Digite"
						name="clauseNumber"
						value={formik.values.clauseNumber}
						onChange={formik.handleChange}
						error={formik.errors.clauseNumber}
						className={formik.errors.clauseNumber ? "input-error" : ""}
						disabled={fieldsDisabled}
					/>
					<Select
						id="covenant"
						name="covenant"
						label="Contrato vinculado"
						options={covenants}
						onChange={(selected: any) => {
							formik.setFieldValue("covenant", selected.value);
						}}
						value={covenants.find(
							(covenant) =>
								covenant.value && covenant.value === formik.values.covenant
						)}
						placeholder="Selecione"
						error={hasErrorsOnSubmit ? formik.errors.covenant : undefined}
						className={
							hasErrorsOnSubmit && formik.errors.covenant ? "select-error" : ""
						}
						isSearchable
						isDisabled={fieldsDisabled || isEdit}
					/>
				</div>

				<div className="clause-creation-field-container description-field-container">
					<TextArea
						id="description"
						name="description"
						label="Descrição da cláusula"
						placeholder="Digite"
						className={
							hasErrorsOnSubmit && formik.errors.description
								? "textarea-error"
								: ""
						}
						value={formik.values.description}
						onChange={(e) => {
							formik.handleChange(e);
							setCharCount(e.target.value.length);
						}}
						error={hasErrorsOnSubmit ? formik.errors.description : undefined}
						rows={5}
						disabled={fieldsDisabled}
					/>
					<span className="character-count">{charCount}/150 Caracteres</span>
				</div>
				<div className="clause-creation-field-container clause-category-select">
					<Select
						id="clauseCategory"
						name="clauseCategory"
						label="Categoria da cláusula"
						options={categories.sort((a, b) => a.label.localeCompare(b.label))}
						onChange={(selected: any) => {
							formik.setFieldValue("clauseCategory", selected.value);
						}}
						value={categories.find(
							(clauseCategory) =>
								clauseCategory.value === formik.values.clauseCategory
						)}
						placeholder="Selecione"
						error={hasErrorsOnSubmit ? formik.errors.clauseCategory : undefined}
						className={
							hasErrorsOnSubmit && formik.errors.clauseCategory
								? "select-error"
								: ""
						}
						isDisabled={fieldsDisabled}
					/>
				</div>
				<div className="clause-creation-field-container keyword-field-container">
					<Input
						label="Referência notificação"
						placeholder="Digite"
						name="keyword"
						value={formik.values.keyword}
						onChange={formik.handleChange}
						error={formik.errors.keyword}
						className={
							hasErrorsOnSubmit && formik.errors.keyword ? "input-error" : ""
						}
						disabled={fieldsDisabled || isEdit}
					/>
				</div>
				<div className="clause-creation-field-container">
					<Select
						id="status"
						name="status"
						label="Status"
						options={ClauseStatusOptions}
						onChange={(selected: any) => {
							formik.setFieldValue("status", selected.value);
						}}
						value={ClauseStatusOptions.find(
							(type) => type.value === formik.values.status
						)}
						placeholder="Selecione"
						className="clause-status-field"
						isDisabled={fieldsDisabled || isEdit}
					/>
				</div>
				<div className="clause-creation-field-title">Período</div>
				<div className="clause-creation-field-container">
					<DatePicker
						label="Data início"
						name="startDate"
						value={formik.values.startDate}
						onBlur={formik.handleBlur}
						onChange={(date) => {
							formik.setFieldValue("startDate", date);
						}}
						error={hasErrorsOnSubmit ? formik.errors.startDate : undefined}
						className={
							hasErrorsOnSubmit && formik.errors.startDate
								? "date-picker-error"
								: ""
						}
						disabled={fieldsDisabled || isEdit}
					/>
					<Select
						id="frequency"
						name="frequency"
						label="Periodicidade"
						options={FrequencyOptions}
						onChange={(selected: any) => {
							formik.setFieldValue("frequency", selected.value);
						}}
						value={FrequencyOptions.find(
							(type) => type.value === formik.values.frequency
						)}
						placeholder="Selecione"
						error={hasErrorsOnSubmit ? formik.errors.frequency : undefined}
						className={
							hasErrorsOnSubmit && formik.errors.frequency ? "select-error" : ""
						}
						isDisabled={fieldsDisabled}
					/>
				</div>
				<div
					className="clause-creation-field-title"
					onClick={() => console.log(formik.values)}
				>
					Time
				</div>
				<div>
					<div className="team-selectors-container stack--6">
						{formik.values.monitoringList?.map((_, index) => (
							<div
								key={`team-selector-${index}`}
								className="clause-creation-field-container"
							>
								<Select
									id={`team-${index}`}
									name={`team-${index}`}
									label={`Time ${index + 1}`}
									options={teams.sort((a, b) => a.label.localeCompare(b.label))}
									onChange={(selected: any) => onChangeTeams(selected, index)}
									value={getTeamValue(index)}
									placeholder="Selecione"
									error={
										hasErrorsOnSubmit
											? getTeamFieldError(index, "teamId")
											: undefined
									}
									isOptionDisabled={(option) =>
										isTeamOptionDisabled(option, index)
									}
									className={
										hasErrorsOnSubmit && hasTeamFieldError(index, "teamId")
											? "select-error"
											: ""
									}
									isSearchable
									isDisabled={fieldsDisabled}
								/>
								<Select
									id={`monitoringLevel-${index}`}
									name={`monitoringLevel-${index}`}
									label="Tipo de notificação"
									options={MonitoringLevelOptions}
									onChange={(selected: any) =>
										onChangeNotification(selected, index)
									}
									value={getNotificationValue(index)}
									placeholder="Selecione"
									error={
										hasErrorsOnSubmit
											? getTeamFieldError(index, "monitoringLevel")
											: undefined
									}
									className={
										hasErrorsOnSubmit &&
										hasTeamFieldError(index, "monitoringLevel")
											? "select-error"
											: ""
									}
									isDisabled={fieldsDisabled}
								/>
								{formik?.values?.monitoringList &&
								formik.values.monitoringList.length > 1 ? (
									<Button
										cssClass="remove-team-button"
										kind="icon"
										styled="ghost"
										size="medium"
										onClick={() => removeTeam(index)}
										disabled={
											fieldsDisabled ||
											(formik.values.monitoringList
												? formik.values.monitoringList.length <= 1
												: true)
										}
									>
										<IcoClose />
									</Button>
								) : null}
							</div>
						))}
					</div>
					<Button
						kind="link"
						disabled={fieldsDisabled}
						styled="ghost"
						size="small"
						onClick={addTeam}
					>
						{" "}
						<IcoPlus />
						<div>Adicionar outro time</div>
					</Button>
				</div>
			</div>
			<div className="buttons">
				<Button
					cssClass="cancel-button"
					kind="default"
					styled="secondary"
					type="button"
					size="medium"
					onClick={() =>
						hasChanges() ? setIsCancelModalOpen(true) : navigate("/clauses")
					}
				>
					Cancelar
				</Button>
				<Button
					cssClass="submit-button"
					kind="default"
					styled="primary"
					type="button"
					size="medium"
					onClick={(e: any) => validateThenHandleSubmit(e)}
					disabled={fieldsDisabled || !formik.dirty}
					isLoading={isLoading}
				>
					Salvar cláusula
				</Button>
			</div>
			<ModalCancelConfirm
				modalTitle="Deseja sair sem salvar?"
				modalInfo="Ao sair sem salvar, suas ações serão perdidas"
				isOpen={isCancelModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Sair sem salvar"
				confirmLabel="Salvar"
				toastSuccessTitle={isEdit ? "Cláusula editada" : "Cláusula criada"}
				toastSuccessMessage={
					isEdit
						? "As informações da cláusula foram alteradas"
						: "Nova cláusula adicionada"
				}
				kind="warning"
			/>
		</form>
	);
}
