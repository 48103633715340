import { Route, Routes } from "react-router-dom";

import {
	CategoryCreatePage,
	CategoryList,
	ClauseCreateEditPage,
	ClauseDetailsPage,
	ClauseList,
	CovenantCreateEditPage,
	CovenantCreationSuccess,
	CovenantDetailsPage,
	ImportError as CovenantImportError,
	ImportErrorDetail as CovenantImportErrorDetail,
	CovenantList,
	Page404,
	TaskList,
	TeamList,
	UserList
} from "pages";
import CompleteTaskPage from "pages/Tasks/Complete";
import TeamCreateEdit from "pages/Teams/CreateAndEdit";

export default function MasterRoutes() {
	return (
		<Routes>
			<Route path="/covenants" element={<CovenantList />} />
			<Route path="/covenants/details/:id" element={<CovenantDetailsPage />} />
			<Route path="/covenants/create" element={<CovenantCreateEditPage />} />
			<Route path="/covenants/edit/:id" element={<CovenantCreateEditPage />} />
			<Route
				path="/covenants/create/success"
				element={<CovenantCreationSuccess />}
			/>
			<Route path="/covenants/create/error" element={<CovenantImportError />} />
			<Route
				path="/covenants/create/error/details"
				element={<CovenantImportErrorDetail />}
			/>
			<Route path="/users" element={<UserList />} />
			<Route path="/teams" element={<TeamList />} />
			<Route path="/teams/edit/:id" element={<TeamCreateEdit />} />
			<Route path="/teams/create" element={<TeamCreateEdit />} />
			<Route path="/clauses" element={<ClauseList />} />
			<Route path="/clauses/details/:id" element={<ClauseDetailsPage />} />
			<Route path="/clauses/create" element={<ClauseCreateEditPage />} />
			<Route path="/clauses/edit/:id" element={<ClauseCreateEditPage />} />
			<Route path="/tasks/complete/:id" element={<CompleteTaskPage />} />
			<Route path="/tasks" element={<TaskList />} />
			<Route path="/categories" element={<CategoryList />} />
			<Route path="/categories/create" element={<CategoryCreatePage />} />
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}
