import IcoLoadingNeutral from "assets/icons/ico-loading-neutral.png";
import IcoLoadingPrimary from "assets/icons/ico-loading-primary.png";
import IcoLoadingSecondary from "assets/icons/ico-loading-secondary.png";
import { LoadingProps } from "./types";

import "./styles.scss";

export default function Loading({ type = "primary", size = 24 }: LoadingProps) {
	let image;

	switch (type) {
		case "primary":
			image = IcoLoadingPrimary;
			break;
		case "secondary":
			image = IcoLoadingSecondary;
			break;
		default:
			image = IcoLoadingNeutral;
			break;
	}

	return (
		<div className="container-loading">
			<img src={image} width={size} height={size} alt="Carregando..." />
		</div>
	);
}
