import { IcoArrowDown, IcoArrowLeft, IcoArrowRight } from "assets/icons";
import colors from "colors";
import Button from "components/Button";
import { useState } from "react";
import ReactSelect, { components } from "react-select";
import "./styles.scss";

type PaginationProps = {
	totalPages: number;
	currentPage: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	pageSize: number;
};

function DropdownIndicator(props: any): any {
	return (
		<components.DropdownIndicator {...props}>
			<IcoArrowDown />
		</components.DropdownIndicator>
	);
}
export default function Pagination({
	totalPages,
	currentPage,
	onPageChange,
	pageSize
}: PaginationProps) {
	const [currentSelectedPage, setCurrentSelectedPage] = useState(currentPage);

	const OptionsValues: any = [
		{ value: 10, label: "10" },
		{ value: 50, label: "50" },
		{ value: 100, label: "100" }
	];

	return (
		<div className="table-pagination">
			<span className="page-size-selector">
				<ReactSelect
					className="basic-single"
					value={pageSize}
					placeholder={pageSize}
					options={OptionsValues}
					onChange={(selected: any) => {
						setCurrentSelectedPage(1);
						onPageChange(1, +selected.value);
					}}
					components={{ DropdownIndicator, IndicatorSeparator: () => null }}
				/>
				Linhas por página
			</span>
			<div className="page-num-selector">
				{currentPage !== 1 && (
					<Button
						kind="icon"
						styled="ghost"
						disabled={currentPage === 1}
						onClick={() => {
							setCurrentSelectedPage(currentSelectedPage - 1);
							onPageChange(currentSelectedPage - 1, pageSize);
						}}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
				)}
				<div className="page-selector">
					<span>Página</span>
					<input
						className="page-number-input"
						type="number"
						max={totalPages}
						min={1}
						step={1}
						value={
							currentSelectedPage < 10
								? `0${currentSelectedPage}`
								: currentSelectedPage
						}
						onChange={(event) => {
							const value = +event.target.value;
							setCurrentSelectedPage(value);
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								if (
									currentSelectedPage >= 1 &&
									currentSelectedPage <= totalPages
								) {
									onPageChange(currentSelectedPage, pageSize);
								}
							}
						}}
					/>
					<span>de {`${totalPages}`}</span>
				</div>
				{currentPage !== totalPages && (
					<Button
						kind="icon"
						styled="ghost"
						onClick={() => {
							setCurrentSelectedPage(currentSelectedPage + 1);
							onPageChange(currentSelectedPage + 1, pageSize);
						}}
					>
						<IcoArrowRight color={colors.neutral["low-pure-500"]} />
					</Button>
				)}
			</div>
		</div>
	);
}
