import { ContractingCompany } from "models/covenant/ContractingCompany";
import { BaseServices } from "./baseServices";

export class ContractingCompanyService extends BaseServices<ContractingCompany> {
	constructor() {
		super(ContractingCompany);
	}

	async listContractingCompanies() {
		return this.list("/contracting-companies", {});
	}
}
