import {
	ColumnDef,
	OnChangeFn,
	RowModel,
	RowSelectionState,
	SortingState
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IcoArrowUp, IcoCalendar, IcoGroup } from "assets/icons";
import { Button, ColoredTag, Pagination, Table } from "components";
import ModalCancelConfirm from "components/ModalCancelConfirm";

import colors from "colors";
import { useQuerystringDetails } from "hooks/querystringFilter";
import { Clause } from "models/covenant/Clause";
import { ClauseStatus } from "models/covenant/enums";
import { ClauseService } from "services/clauses";

import ChangeFrequencyDrawer from "./ChangeFrequencyDrawer";
import ChangeTeamDrawer from "./ChangeTeamDrawer";
import ClauseDetailsDrawer from "./DetailsDrawer";

import "./styles.scss";

type ClauseTableProps = {
	content: Clause[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	reloadData: () => void;
	setFilters: (value: any) => void;
};

export default function ClausesTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting,
	reloadData,
	setFilters
}: ClauseTableProps) {
	const navigate = useNavigate();
	const clauseService = new ClauseService();
	const [isScrolledDown, setIsScrolledDown] = useState(false);
	const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

	const [isModalChangeFrequencyOpen, setIsModalChangeFrequencyOpen] =
		useState(false);
	const [isModalChangeTeamOpen, setIsModalChangeTeamOpen] = useState(false);
	const [closingClause, setClosingClause] = useState<Clause>();
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [selectionRowModel, setSelectedRowModel] = useState<RowModel<any>>();
	const {
		itemForInspection,
		getItemForInspectionFromUrl,
		setItemForInspectionInUrl
	} = useQuerystringDetails(content, "clauseId", setFilters);

	const getStatusType = (statusValue: string): "default" | "success" => {
		return statusValue === ClauseStatus.Ativa.name ? "success" : "default";
	};

	const columns: ColumnDef<Clause>[] = [
		{
			id: "clauseNumber",
			accessorKey: "clauseNumber",
			header: "Número",
			meta: {
				columnClass: "clause-number-column"
			}
		},
		{
			id: "clauseCategory",
			accessorKey: "clauseCategory.name",
			header: "Categoria",
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "clause-category-column"
			}
		},
		{
			id: "covenantNumber",
			accessorKey: "covenant.covenantNumber",
			header: "Nº contrato",
			meta: {
				columnClass: "covenant-number-column"
			}
		},
		{
			id: "teams",
			accessorKey: "teamsNames",
			header: "Time vinculado",
			enableSorting: false,
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "teams-column"
			}
		},
		{
			id: "status",
			accessorKey: "status.name",
			header: () => <span>Status</span>,
			cell: (row) => {
				return (
					<ColoredTag
						text={row.getValue() as string}
						type={getStatusType(row.getValue() as string)}
					/>
				);
			},
			meta: {
				columnClass: "status-column"
			}
		},
		{
			id: "frequency",
			accessorKey: "frequency.name",
			header: "Periodicidade",
			meta: {
				columnClass: "frequency-column"
			}
		}
	];

	const closeClause = async () => {
		return clauseService
			.close(closingClause?.clauseId as string)
			.then(() => onPageChange(currentPage, pageSize))
			.finally(() => {
				setClosingClause(undefined);
				setIsCloseModalOpen(false);
			});
	};

	const selectionLabel = () => {
		const numberRows = Object.keys(rowSelection).length;
		const labelName = numberRows > 1 ? "cláusulas" : "cláusula";
		const labelSelected = numberRows > 1 ? "selecionadas" : "selecionada";
		return (
			<>
				<b>{`${numberRows} ${labelName} `}</b>
				{`${labelSelected} no total`}
			</>
		);
	};

	const selectionActions = () => {
		const rowStatusNames = selectionRowModel?.rows.map(
			(row) => row.original.status.name
		);

		const isClosed = rowStatusNames?.includes("Encerrada");
		const closedCount = rowStatusNames?.filter(
			(status) => status === "Encerrada"
		)?.length;

		return !isClosed ? (
			<>
				<Button onClick={() => setIsModalChangeTeamOpen(true)}>
					<IcoGroup size="20" color={colors.neutral["high-pure-50"]} />
					<div>Alterar times</div>
				</Button>
				<Button
					styled="secondary"
					onClick={() => setIsModalChangeFrequencyOpen(true)}
				>
					<IcoCalendar size="20" />
					<div>Alterar periodicidade</div>
				</Button>
			</>
		) : (
			<p>
				{closedCount === 1
					? `${closedCount} Cláusula está encerrada`
					: `${closedCount} Cláusulas estão encerradas`}
				<span className="alert-closed">
					Atenção: não é possível realizar ações em cláusulas encerradas
				</span>
			</p>
		);
	};

	const checkScrollTop = () => {
		const minPixelScrolled = 300;
		setIsScrolledDown(window.scrollY > minPixelScrolled);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	const getSubRowComponent = (row: any, columnsSubRow: any[]) => {
		const subrowLength = columnsSubRow.length;

		return (
			<tr className="expanded-description-row" key={row.id}>
				<td className="select-column"> </td>
				<td className="subrow-column" colSpan={subrowLength}>
					<div className="content">
						<div className="subrow-content">{row.original.description}</div>
						<div className="subrow-buttons">
							<Button
								size="small"
								styled="transparent"
								onClick={() => setItemForInspectionInUrl(row.original)}
							>
								Mostrar detalhes
							</Button>
							{row.original.status.name !== "Encerrada" && (
								<>
									<Button
										size="small"
										styled="transparent"
										onClick={() =>
											navigate(`/clauses/edit/${row.original.clauseId}`)
										}
									>
										Editar cláusula
									</Button>
									<Button
										size="small"
										onClick={() => {
											setClosingClause(row.original);
											setIsCloseModalOpen(true);
										}}
									>
										Encerrar cláusula
									</Button>
								</>
							)}
						</div>
					</div>
				</td>
			</tr>
		);
	};

	useEffect(() => getItemForInspectionFromUrl(), []);

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
		return () => window.removeEventListener("scroll", checkScrollTop);
	}, []);

	return (
		<div className="clauses-table">
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
				rowSelection={rowSelection}
				setRowSelection={setRowSelection}
				selectionActions={selectionActions()}
				selectionLabel={selectionLabel}
				setSelectedRowModel={setSelectedRowModel}
				canExpand="end"
				getRowCanExpand={(row) => !!row.original.description}
				getSubRows={(row) =>
					row instanceof Clause
						? [
								{
									description: row?.description,
									clauseId: row.clauseId,
									clauseNumber: row.clauseNumber,
									startDate: row.startDate,
									status: row.status,
									frequency: row.frequency,
									covenant: row.covenant,
									clauseCategory: row.clauseCategory,
									teams: row.teams,
									completionDate: row.completionDate
								}
						  ]
						: []
				}
				getSubRowComponent={getSubRowComponent}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<ClauseDetailsDrawer
				isOpen={!!itemForInspection}
				onClose={() => setItemForInspectionInUrl(undefined)}
				clause={itemForInspection!}
			/>
			<ChangeFrequencyDrawer
				isOpen={isModalChangeFrequencyOpen}
				onClose={() => setIsModalChangeFrequencyOpen(false)}
				clausesIds={
					selectionRowModel
						? selectionRowModel?.rows.map(
								(row) => row.original.clauseId as string
						  )
						: []
				}
				reloadTable={reloadData}
			/>
			<ChangeTeamDrawer
				isOpen={isModalChangeTeamOpen}
				onClose={() => setIsModalChangeTeamOpen(false)}
				clausesIds={
					selectionRowModel
						? selectionRowModel?.rows.map(
								(row) => row.original.clauseId as string
						  )
						: []
				}
				reloadTable={reloadData}
			/>
			<ModalCancelConfirm
				modalTitle="Encerrar cláusula"
				modalInfo="Ao confirmar, todas as ações das cláusulas será finalizadas"
				isOpen={isCloseModalOpen}
				onClose={() => {
					setClosingClause(undefined);
					setIsCloseModalOpen(false);
				}}
				onConfirm={closeClause}
				toastSuccessTitle="Cláusula encerrada"
				toastSuccessMessage="A cláusula foi encerrada"
				kind="warning"
			/>
			{isScrolledDown && (
				<Button
					onClick={scrollToTop}
					kind="icon"
					cssClass="scroll-to-top-button"
				>
					<IcoArrowUp color={colors.neutral["high-100"]} />
				</Button>
			)}
		</div>
	);
}
