import { IconProps } from "./types";

export default function IcoImport({
	color = "#0D0D0D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="export-import">
				<path
					id="Vector"
					d="M8 2V10M8 10L12 6.4M8 10L4 6.4"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M14 10.0039V13.0039C14 13.5562 13.5523 14.0039 13 14.0039H3C2.44772 14.0039 2 13.5562 2 13.0039V10.0039"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
