import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Clause } from "models/covenant/Clause";

export interface ClauseState {
	clauseCreated?: Clause;
	clauseEdited?: Clause;
}

const initialState = {
	importErrors: undefined,
	clauseCreated: undefined,
	clauseEdited: undefined
} as ClauseState;

export const clauseSlice = createSlice({
	name: "clause",
	initialState,
	reducers: {
		setClauseCreated: (state, action: PayloadAction<Clause>) => {
			state.clauseCreated = action.payload;
		},
		setClauseEdited: (state, action: PayloadAction<Clause>) => {
			state.clauseEdited = action.payload;
		},
		clear: () => initialState
	}
});

export const { setClauseCreated, setClauseEdited, clear } = clauseSlice.actions;
export default clauseSlice.reducer;
