import { IcoArrowRight, IcoExport, IcoPencil } from "assets/icons";
import colors from "colors";
import { Button, Loading } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CovenantService } from "services/covenants";
import "./styles.scss";

import ToastContent from "components/ToastContent";
import { PdfViewerDetailsContainer } from "features";
import { FileHelper } from "helpers";
import { Team } from "models/auth/Team";
import { Covenant } from "models/covenant/Covenant";
import { toast } from "react-toastify";

export default function CovenantDetailsPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [covenant, setCovenant] = useState<Covenant>();
	const covenantService = new CovenantService();
	const [loading, setLoading] = useState(false);

	const getCovenant = () => {
		setIsFetchingData(true);

		if (!id) {
			console.debug("Id inválido!");
			return;
		}

		covenantService
			.retrieve(id)
			.then((response: any) => {
				setCovenant(response);
				setIsFetchingData(false);
			})
			.catch((error: any) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	useEffect(() => {
		getCovenant();
	}, []);

	const exportCovenant = () => {
		setLoading(true);
		if (!covenant || !covenant.covenantId) {
			return;
		}

		covenantService
			.exportCovenants([covenant.covenantId])
			.then((response) => {
				setLoading(false);
				FileHelper.downloadExcelFile(
					response,
					`${covenant.exportFileName}.xlsx`
				);
			})
			.catch((error) => {
				setLoading(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	return isFetchingData ? (
		<Loading type="primary" />
	) : (
		<PdfViewerDetailsContainer
			onClickReturn={() => navigate("/covenants")}
			actionButtons={
				<>
					<Button
						kind="default"
						styled="transparent"
						onClick={() => exportCovenant()}
						cssClass={loading ? "loading-upload-button" : "upload-button"}
					>
						<IcoExport color={colors.neutral["low-pure-500"]} size="1.5rem" />
						Baixar
					</Button>
					<Button
						kind="default"
						styled="primary"
						onClick={() => navigate(`/covenants/edit/${id}`)}
						cssClass="edit-button"
					>
						<IcoPencil color={colors.neutral["high-pure-50"]} size="1.5rem" />
						Editar
					</Button>
				</>
			}
			pdfUrl={covenant?.covenantPdf}
			detailsTitle="Informações do contrato"
			infosDetails={[
				{
					title: "Contrato",
					infos: [
						{
							label: "Tipo de contrato",
							value: covenant?.covenantType?.name,
							className: "contracting-company-label"
						},
						{
							label: "Número do contrato",
							value: covenant?.covenantNumber ?? "",
							className: "covenant-number-label"
						},
						{
							label: "Moeda",
							value: covenant?.currency?.name
						},
						{
							label: "Valor do contrato",
							value: covenant?.totalValue.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							})
						},
						{
							label: "Data da assinatura",
							value: covenant?.signatureDate?.toLocaleDateString()
						},
						{
							label: "Data de vencimento",
							value: covenant?.expirationDate?.toLocaleDateString()
						},
						{
							label: "Status",
							value: covenant?.status?.name
						},
						{
							label: "Descrição do contrato",
							value: covenant?.description ?? ""
						}
					]
				},
				{
					title: "Contratante",
					infos: [
						{
							label: "Empresas contratantes",
							value: covenant?.contractingCompaniesNames ?? ""
						},
						{
							label: "Tipo de contratante",
							value: covenant?.contractingType?.name
						}
					]
				},
				{
					title: "Credor",
					infos: [
						{
							label: "Credor",
							value: covenant?.creditor?.name
						},
						{
							label: "Tipo de credor",
							value: covenant?.creditorType?.name
						}
					]
				},
				{
					title: "Times vinculados",
					infos: covenant?.teams.map((team: Team) => ({
						label: team.name,
						alignClass: "center-align",
						value: (
							<Button
								cssClass="right-arrow"
								kind="icon"
								styled="ghost"
								type="button"
								onClick={() => navigate(`/teams/edit/${team.id}`)}
							>
								<IcoArrowRight color={colors.neutral["low-pure-500"]} />
							</Button>
						)
					})) ?? [{ label: "Sem times associados" }]
				}
			]}
		/>
	);
}
