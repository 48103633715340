import { Task } from "models/covenant/Task";
import { ObjectWithAnyValues } from "utils/types";
import {
	TaskBatchCompletionRequestProps,
	TaskCompletionRequestProps
} from "models/covenant/types";
import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class TaskService extends BaseServices<Task> {
	constructor() {
		super(Task);
	}

	async listTasksPaginated(
		params: ObjectWithAnyValues
	): Promise<BasePageResponse> {
		return super.listPaginated("tasks", params);
	}

	async complete(id: string, task: TaskCompletionRequestProps) {
		return super.patch(`tasks/${id}/complete`, task);
	}

	async completeBatch(tasks: TaskBatchCompletionRequestProps) {
		return super.patch(`tasks/complete`, tasks);
	}

	async exportTasks(filters: ObjectWithAnyValues) {
		const params = {
			params: { ...filters },
			paramsSerializer: { indexes: null },
			responseType: "blob"
		};
		return this.get("tasks/export", params);
	}
}
