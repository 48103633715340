import { SortingState } from "@tanstack/react-table";

const TableHelper = {
	sortingStateToParam(sorting: SortingState, defaultSort = "") {
		return sorting && sorting.length > 0
			? {
					sort: `${sorting[0].id}${sorting[0].desc ? ",desc" : ""}`
			  }
			: { sort: defaultSort };
	}
};

export default TableHelper;
