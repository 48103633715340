import { IcoArrowDown } from "assets/icons";
import ReactSelect, { Props, components } from "react-select";
import "./styles.scss";

interface MultiSelectProps extends Props {
	label?: string;
	onClear?: () => void;
	clearLabel?: string;
	error?: string | undefined;
	value: [] | any;
}

function DropdownIndicator(props: any): any {
	return (
		<components.DropdownIndicator {...props}>
			<IcoArrowDown />
		</components.DropdownIndicator>
	);
}
function CustomClearIndicator() {
	return null;
}

function Option(props: any) {
	return (
		<components.Option {...props}>
			<div className="option">
				<span>{props?.label}</span>
				<input
					type="checkbox"
					checked={props?.isSelected}
					onChange={() => null}
				/>
			</div>
		</components.Option>
	);
}

export default function Multiselect({
	options,
	onChange,
	value,
	placeholder,
	className,
	onInputChange,
	label,
	onClear,
	clearLabel,
	error,
	...args
}: MultiSelectProps) {
	return (
		<div className="multiselect-group">
			<div
				className={
					args.isDisabled
						? "body-1 multiselect-label-disabled"
						: "body-1 multiselect-label"
				}
			>
				{label}
			</div>
			<ReactSelect
				isMulti
				options={options}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				onInputChange={onInputChange}
				className={`multiselect-selector ${className || ""}`}
				classNamePrefix="select"
				components={{
					DropdownIndicator,
					IndicatorSeparator: () => null,
					ClearIndicator: CustomClearIndicator,
					Option
				}}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#c6c7c854"
					}
				})}
				{...args}
			/>
			<span className="multiselect-clear" onClick={onClear}>
				{value?.length > 0 && "limpar tudo"}
			</span>
			{error && <div className="error-message">{error}</div>}
		</div>
	);
}
