import colors from "colors";
import { IconProps } from "./types";

export default function IcoClip({
	color = colors.neutral["low-pure-500"],
	size = "1.5rem"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 17.1429V8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8V17.2381C16 18.7635 14.7635 20 13.2381 20C11.7128 20 10.4762 18.7635 10.4762 17.2381V9.01882C10.4762 8.17725 11.1584 7.49502 12 7.49502C12.8416 7.49502 13.5238 8.17725 13.5238 9.01882V17.1429"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
