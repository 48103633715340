import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable
} from "@tanstack/react-table";
import { IcoArrowLeft, IcoPlusSecondary } from "assets/icons";
import colors from "colors";
import { Button } from "components";
import { ColumnMetaProps } from "components/Table/types";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { RootState } from "store";
import { ImportErrorItem } from "store/features/covenant/slice";
import "./styles.scss";

export default function ImportErrorDetail() {
	const navigate = useNavigate();
	const { importErrors } = useSelector((state: RootState) => state.covenant);

	const columns: ColumnDef<ImportErrorItem>[] = [
		{
			id: "line",
			accessorKey: "line",
			header: "Linha",
			cell: (row) => `Linha ${row.getValue()}`,
			meta: {
				columnClass: "line-column"
			}
		},
		{
			id: "type",
			accessorKey: "type",
			header: "Aba",
			meta: {
				columnClass: "type-column"
			}
		},
		{
			id: "field",
			accessorKey: "field",
			header: "Nome do campo",
			meta: {
				columnClass: "field-column"
			}
		},
		{
			id: "error",
			accessorKey: "error",
			header: "Erro"
		},
		{
			id: "action",
			header: "Ações",
			cell: ({ row }) => {
				const { field, error } = row.original;

				const isMissingSummary =
					field === "Resumo" && error.startsWith("Formato ou valor inválido:");

				let categoryName = "";
				if (isMissingSummary) {
					categoryName = error.substring(28, error.length - 1).trim();
				}

				return isMissingSummary ? (
					<>
						<Tooltip anchorSelect=".create-category-button" place="top">
							Criar categoria
						</Tooltip>
						<Button
							kind="icon"
							styled="ghost"
							size="small"
							onClick={() => {
								if (categoryName) {
									navigate(
										`/categories/create?name=${encodeURIComponent(
											categoryName
										)}`
									);
								} else {
									navigate("/categories/create");
								}
							}}
							cssClass="create-category-button"
						>
							<IcoPlusSecondary />
						</Button>
					</>
				) : (
					<span>Sem ação</span>
				);
			},
			meta: {
				columnClass: "action-column"
			}
		}
	];

	const table = useReactTable({
		data: importErrors?.["Arquivo do Contrato"]?.[0].details || [],
		getCoreRowModel: getCoreRowModel(),
		columns
	});

	useEffect(() => {
		if (
			importErrors?.["Arquivo do Contrato"]?.[0]?.message ===
			"O arquivo contém uma aba vazia"
		) {
			toast.error(
				importErrors["Arquivo do Contrato"][0].details.length > 1
					? `As abas "${Object.values(
							importErrors["Arquivo do Contrato"][0].details
					  ).join(", ")}" estão vazias.`
					: `A aba "${
							Object.values(importErrors["Arquivo do Contrato"][0].details)[0]
					  }" está vazia.`
			);
			navigate("/covenants/create/error");
		}
	}, [importErrors]);

	return (
		<div className="covenant-import-error-details-page">
			<div className="error-details-header">
				<div className="flex--row flex-items--center">
					<Button
						kind="icon"
						styled="ghost"
						cssClass="left-arrow"
						onClick={() => navigate("/covenants/create/error")}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="title">Erros na importação</span>
				</div>
			</div>
			<div className="error-details-content stack--3">
				<div className="subtitle">Detalhes</div>
				<div className="timestamp-container">
					<div className="text-neutral-low-400">Data e hora</div>
					<div>
						{moment(importErrors?.timestamp).format("DD/MM/YYYY HH:mm")}
					</div>
				</div>
				<div className="subtitle">Erros</div>
				<table className="covenant-import-error-table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										className={
											`${
												(header.column.columnDef.meta as ColumnMetaProps)
													?.columnClass || ""
											}` +
											` ${
												(header.column.columnDef.meta as ColumnMetaProps)
													?.headerClass || ""
											}`
										}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td
										key={cell.id}
										className={
											`${
												(cell.column.columnDef.meta as ColumnMetaProps)
													?.columnClass || ""
											}` +
											` ${
												(cell.column.columnDef.meta as ColumnMetaProps)
													?.cellClass || ""
											}`
										}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
