import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import colors from "colors";
import {
	Button,
	Container,
	EmptyList as EmptyTeamsList,
	Loading
} from "components";
import { TeamService } from "services/teams";

import { SortingState } from "@tanstack/react-table";
import { IcoPlus } from "assets/icons";
import ToastContent from "components/ToastContent";
import TableHelper from "helpers/TableHelper";
import { useQuerystringParams } from "hooks/querystringFilter";
import { useHandleBack } from "hooks/useHandleBack";
import TeamsListFilters from "./TeamsListFilters";
import TeamsTable from "./TeamsTable";
import "./styles.scss";
import { TeamFilter } from "./types";

export default function TeamList() {
	const teamService = new TeamService();
	const navigate = useNavigate();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [teams, setTeams] = useState<any>([]);
	const [filters, setFilters] = useState<TeamFilter>({
		size: 10,
		page: 1,
		roles: [],
		teams: [],
		textFilter: ""
	});
	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: "name",
			desc: false
		}
	]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<TeamFilter>(
		setFilters,
		sorting,
		setSorting
	);

	const getTeams = () => {
		setIsFetchingData(true);

		const sortParam = TableHelper.sortingStateToParam(sorting);
		const params = {
			...filters,
			...sortParam
		};

		teamService
			.listPaginated(params)
			.then((response) => {
				setTeams(response.content);
				setTotalPages(response.totalPages);
				setIsFetchingData(false);
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	useEffect(() => {
		if (initialized) {
			getTeams();
		} else {
			setInitialized(true);
		}
	}, [filters]);

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;
		const newFilters = {
			size: filters.size,
			page: 1,
			...appliedFilters,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};
		updateUrl(newFilters);
	};

	useHandleBack(navigate);

	return (
		<Container className="teams-page">
			<div className="teams-heading">
				<span className="teams-title">Times</span>
				<TeamsListFilters onApply={onApplyFilters} />
				<Button
					kind="default"
					styled="primary"
					cssClass="create-team-button"
					onClick={() => navigate("/teams/create")}
				>
					<IcoPlus color={colors.neutral["high-pure-50"]} />
					Criar time
				</Button>
			</div>
			{isFetchingData ? (
				<Loading type="primary" />
			) : teams.length === 0 ? (
				<EmptyTeamsList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<TeamsTable
					content={teams}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
					setFilters={setFilters}
				/>
			)}
		</Container>
	);
}
