import { useEffect } from "react";

import ToastContent from "components/ToastContent";
import { baseConfig } from "config";
import CookieHelper from "helpers/CookieHelper";
import { User } from "models/auth/User";
import { UserProps } from "models/auth/types";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthService } from "services/auth";
import { setLoggedIn } from "store/features/auth/slice";
import Container from "../Container";

export default function OAuth2RedirectHandler() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authService = new AuthService();

	const token = searchParams.get(authService.ACCESS_TOKEN);
	const authError = searchParams.get(authService.ERROR);
	const nextPage = searchParams.get(authService.NEXT);

	const handleLogin = (user: User) => {
		sessionStorage.setItem(authService.USER_DATA, JSON.stringify(user));
		dispatch(
			setLoggedIn({
				user,
				isMaster: user.isMaster(),
				isAnalyst: user.isAnalyst(),
				isManager: user.isManager()
			})
		);

		navigate(nextPage ?? "/");
	};

	const handleMockLogin = () => {
		const mockLoggedUser: UserProps = {
			id: 0,
			email: "mail@mail.com",
			enabled: true,
			enabledOn: new Date().toLocaleString(),
			lastLoginDate: new Date().toLocaleString(),
			name: "Mock User",
			role: baseConfig.mockUserRole,
			teams: [
				{
					createdOn: new Date().toLocaleString(),
					id: 1,
					name: "Mock Team",
					clauseCount: 99,
					users: []
				}
			]
		};
		handleLogin(new User(mockLoggedUser));
	};

	useEffect(() => {
		if (baseConfig.mockAuth) {
			handleMockLogin();
			return;
		}

		if (authError || !token) {
			if (!authError) {
				searchParams.set(
					authService.ERROR,
					"Não foi possível obter token de acesso"
				);
			}

			navigate(`/login/error`);
			return;
		}

		// Due to react strict mode, it's passing twice on this point
		// And, on the second point, the cookie wasn't being persisted. So forcing persist
		CookieHelper.setCookies(authService.ACCESS_TOKEN, token, 5);

		authService
			.getCurrentUser()
			.then((user) => {
				handleLogin(user);
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	}, [token]);

	return (
		<Container className="login-redirect">
			<div />
		</Container>
	);
}
