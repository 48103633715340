import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { IcoClose, IcoFilter, IcoSearch } from "assets/icons";
import colors from "colors";
import { Avatar, Button } from "components";
import { Role } from "models/auth/Role";
import { Team } from "models/auth/Team";
import { RoleService } from "services/roles";
import { TeamService } from "services/teams";

import ToastContent from "components/ToastContent";
import { useQuerystringFilters } from "hooks/querystringFilter";
import UserFilterDrawer from "./FilterDrawer";
import "./styles.scss";
import { UserFilterInterface, UserFilterProps } from "./types";

export default function UsersListFilters({ onApply }: UserFilterProps) {
	const [filters, setFilters] = useState<UserFilterInterface>({
		fromDate: undefined,
		untilDate: undefined,
		roles: [],
		teams: [],
		search: ""
	});

	const [qtyFiltersApplied, setQtyFiltersApplied] = useState(0);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [teamList, setTeamList] = useState<Team[]>([]);
	const [roleList, setRoleList] = useState<Role[]>([]);
	const { getUrlFilters } = useQuerystringFilters(filters);
	const roleService = new RoleService();
	const teamService = new TeamService();
	const [isSearch, setIsSearch] = useState(false);

	const onApplyFilters = (appliedFilters: any) => {
		let qtyFilters = 0;
		if (appliedFilters.fromDate) {
			qtyFilters++;
		}
		if (appliedFilters.untilDate) {
			qtyFilters++;
		}
		if (appliedFilters.roles?.length) {
			qtyFilters++;
		}
		if (appliedFilters.teams?.length) {
			qtyFilters++;
		}

		setQtyFiltersApplied(qtyFilters);
		setSearchText(appliedFilters.search || "");

		setFilters({ ...appliedFilters });
		onApply({ ...appliedFilters });
		setIsDrawerOpen(false);
	};

	useEffect(() => {
		roleService
			.list("roles", {})
			.then((roles) => {
				setRoleList(roles);
			})
			.catch((e) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={e}
						onClose={() => toast.dismiss()}
					/>
				);
			});

		teamService
			.list({})
			.then((teams) => {
				setTeamList(teams);
			})
			.catch((e) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={e}
						onClose={() => toast.dismiss()}
					/>
				);
			});

		onApplyFilters(getUrlFilters());
	}, []);

	const onSearchInputApply = (event: React.SyntheticEvent) => {
		if (
			(event as React.KeyboardEvent).key === "Enter" ||
			(event as React.MouseEvent).type === "click"
		) {
			const newFilters = {
				...filters,
				search: searchText
			};
			setFilters(newFilters);
			onApply(newFilters);
			if (searchText.length > 0) {
				setIsSearch(true);
			}
		}
	};

	const resetFilters = () => {
		const emptyFilters = {
			fromDate: undefined,
			untilDate: undefined,
			roles: [],
			teams: [],
			search: ""
		};
		setSearchText("");
		setIsSearch(false);
		setFilters(emptyFilters);
		setQtyFiltersApplied(0);
		onApply(emptyFilters);
		setIsDrawerOpen(false);
	};
	const resetSearch = () => {
		setSearchText("");
		setIsSearch(false);
		const newFilters = {
			...filters,
			search: ""
		};
		onApply(newFilters);
	};

	return (
		<>
			<div className="filter-wrapper">
				{qtyFiltersApplied > 0 && (
					<span className="clear-filters" onClick={resetFilters}>
						Limpar filtros
					</span>
				)}
				<Button
					kind="chip"
					styled={qtyFiltersApplied > 0 ? "primary" : "secondary"}
					onClick={() => setIsDrawerOpen(true)}
					cssClass="filter-button"
				>
					{qtyFiltersApplied === 0 && (
						<IcoFilter color={colors.neutral["low-pure-500"]} />
					)}
					Filtro
					{qtyFiltersApplied > 0 && (
						<Avatar size="sm" cssClass="filter-qty">
							{qtyFiltersApplied}
						</Avatar>
					)}
				</Button>
				<div className="divider" />
				<div className="search-filter">
					<input
						placeholder="Pesquisa"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						onKeyDown={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					/>
					<div
						className="search-icon-wrapper"
						onClick={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					>
						{isSearch ? (
							<IcoClose size="1rem" />
						) : (
							<IcoSearch color={colors.neutral["low-pure-500"]} size="1rem" />
						)}
					</div>
				</div>
			</div>
			<UserFilterDrawer
				fromDate={filters.fromDate}
				untilDate={filters.untilDate}
				roles={filters.roles}
				teams={filters.teams}
				search={filters.search}
				onApplyFilters={onApplyFilters}
				isOpen={isDrawerOpen}
				onClickOutside={() => setIsDrawerOpen(false)}
				allTeamList={teamList}
				roleList={roleList}
			/>
		</>
	);
}
