import { IcoArrowLeft } from "assets/icons";
import colors from "colors";
import { Button, InputFile, Select, TextArea } from "components";
import { Task } from "models/covenant/Task";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import ModalCancelConfirm from "components/ModalCancelConfirm";
import ToastContent from "components/ToastContent";
import { useFormik } from "formik";
import { TaskStatusOptions } from "models/covenant/enums";
import { TaskCompletionProps } from "models/covenant/types";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FileService } from "services/file";
import { TaskService } from "services/tasks";

export default function CompleteTaskPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const fileService = new FileService();
	const taskService = new TaskService();
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [InputFileName, setInputFileName] = useState("");
	const [initialValues, setInitialValues] = useState<TaskCompletionProps>(
		Task.initialValues
	);
	const [hasErrorsOnSubmit, sethasErrorsOnSubmit] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const AZURE_TASK_DOCUMENT_CONTAINER_NAME = "tasksdocuments";

	const completeTask = (values: TaskCompletionProps) => {
		setIsLoading(true);
		taskService
			.complete(id!, {
				status: values.status!,
				dueDate: values.dueDate,
				observation: values.observation,
				documentUrl: values.documentUrl
			})
			.then(() => {
				setIsLoading(false);
				navigate("/tasks");
				toast.dark(
					<ToastContent
						type="success"
						title="Tarefa concluída"
						subtitle="A tarefa foi concluída."
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema: Task.validationSchema,
		onSubmit: (values) => {
			if (!id || !values.status) {
				return;
			}

			if (!values.documentFile) {
				completeTask(values);
				return;
			}

			fileService
				.uploadFile(values.documentFile, AZURE_TASK_DOCUMENT_CONTAINER_NAME)
				.then((documentUrl: string) => {
					values.documentUrl = documentUrl;
					completeTask(values);
				})
				.catch((error) =>
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					)
				);
		}
	});

	const acceptedFileTypes = [
		"application/pdf",
		"image/jpeg",
		"image/png",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
	];

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0];
		if (!selectedFile) return;

		if (acceptedFileTypes.includes(selectedFile.type)) {
			setInputFileName(selectedFile.name);
			formik.setFieldValue("documentFile", selectedFile);
		} else {
			toast.error(
				<ToastContent
					type="error"
					title="Erro"
					subtitle="O arquivo enviado não é do tipo PDF, JPG, PNG ou DOCX"
					onClose={() => toast.dismiss()}
				/>
			);
		}
	};

	const closeModal = () => {
		setIsCancelModalOpen(false);
		navigate("/tasks");
	};

	const confirmModalAction = () => {
		if (formik.isValid) {
			formik.submitForm();
			setIsCancelModalOpen(false);
			return true;
		}
		setIsCancelModalOpen(false);
		return false;
	};

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(initialValues);

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			sethasErrorsOnSubmit(true);
		} else {
			formik.handleSubmit();
		}
	};

	useEffect(() => {
		const values = new Task(location.state?.completingTask).asCompletionValues;

		if (values) {
			setInitialValues({
				...values
			});
			formik.setValues(() => ({
				...values
			}));
		}
	}, []);

	return (
		<form
			className="clause-completion-page"
			onSubmit={validateThenHandleSubmit}
		>
			<div className="clause-completion-header">
				<div className="flex--row flex-items--center">
					<Button
						kind="icon"
						styled="ghost"
						cssClass="left-arrow"
						onClick={() =>
							hasChanges() ? setIsCancelModalOpen(true) : navigate("/tasks")
						}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="clause-completion-title">Tarefa</span>
				</div>
			</div>
			<p className="complete-details-info-title">Informações da tarefa</p>
			<div className="complete-details-info-wrapper">
				<div className="complete-details-info-row start-align">
					<span className="complete-details-info-label w-1/2">
						Tipo da tarefa
					</span>
					<span className="complete-details-info-value w-1/2">
						{formik.values.clauseCategory}
					</span>
				</div>
				<div className="complete-details-info-row start-align">
					<span className="complete-details-info-label w-1/2">Contrato</span>
					<span className="complete-details-info-value w-1/2">
						{formik.values.covenant && formik.values.covenant.covenantNumber}
					</span>
				</div>
				<div className="complete-details-info-row start-align">
					<span className="complete-details-info-label w-1/2">
						Tipo de contrato
					</span>
					<span className="complete-details-info-value w-1/2">
						{formik.values.covenant?.covenantType?.name}
					</span>
				</div>
				<div className="complete-details-info-row start-align">
					<span className="complete-details-info-label w-1/2">Vencimento</span>
					<span className="complete-details-info-value w-1/2">
						{moment(formik.values.dueDate).format("DD/MM/YYYY")}
					</span>
				</div>
			</div>
			<p className="complete-details-info-title pb-0">Descrição da cláusula</p>
			<div className="complete-details-info-wrapper clause-description">
				<div className="complete-details-info-row start-align no-bottom-border">
					<span className="complete-details-info-label whitespace-pre-wrap">
						{formik.values.description}
					</span>
				</div>
			</div>
			<div className="clause-completion-fields-group pt-0">
				<div className="clause-completion-field-container">
					<Select
						id="status"
						name="status"
						label="Status da tarefa"
						options={TaskStatusOptions}
						onChange={(selected: any) => {
							formik.setFieldValue("status", selected.value);
						}}
						value={TaskStatusOptions.find(
							(type) => type.value === Number(formik.values.status)
						)}
						error={hasErrorsOnSubmit ? formik.errors.status : undefined}
						placeholder="Selecione"
						className={
							hasErrorsOnSubmit && formik.errors.status
								? "clause-status-field select-error"
								: "clause-status-field"
						}
					/>
				</div>
				<div className="clause-completion-field-container description-field-container">
					<TextArea
						id="observation"
						name="observation"
						label="Observações"
						placeholder="Escreva aqui"
						value={formik.values.observation}
						error={hasErrorsOnSubmit ? formik.errors.observation : ""}
						onChange={(e) => {
							formik.handleChange(e);
							setCharCount(e.target.value.length);
						}}
						rows={5}
						className={
							hasErrorsOnSubmit && formik.errors.observation
								? "textarea-error"
								: ""
						}
					/>
					<span className="character-count">{charCount}/150 Caracteres</span>
				</div>
				<div className="clause-completion-field-container">
					<InputFile
						id="covenant-file"
						title={
							InputFileName !== "" ? "Documento Anexado" : "Anexar documento"
						}
						description={
							InputFileName !== "" ? InputFileName : "PDF, JPG, PNG ou DOCX"
						}
						accept={acceptedFileTypes.join(",")}
						file={formik.values.documentFile}
						error={hasErrorsOnSubmit ? formik.errors.documentUrl : undefined}
						onChange={handleFileChange}
						successMessage="Completo"
						onClickRemoveFile={() => {
							setInputFileName("");
							formik.setFieldValue("documentFile", undefined);
						}}
						onClickRetry={() => {}}
						className={
							hasErrorsOnSubmit && formik.errors.documentUrl
								? "input-file-error"
								: ""
						}
					/>
				</div>
			</div>
			<div className="buttons">
				<Button
					cssClass="cancel-button"
					kind="default"
					styled="secondary"
					type="button"
					size="medium"
					onClick={() =>
						hasChanges() ? setIsCancelModalOpen(true) : navigate("/tasks")
					}
				>
					Voltar
				</Button>
				<Button
					cssClass="submit-button"
					kind="default"
					styled="primary"
					type="submit"
					size="medium"
					disabled={!formik.dirty}
					isLoading={isLoading}
				>
					Concluir tarefa
				</Button>
			</div>
			<ModalCancelConfirm
				modalTitle="Deseja sair sem concluir?"
				modalInfo="Ao sair sem concluir, suas ações serão perdidas"
				isOpen={isCancelModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Sair sem concluir"
				confirmLabel="Concluir tarefa"
				toastSuccessTitle="Tarefa concluída"
				toastSuccessMessage="A tarefa foi concluída"
				kind="warning"
			/>
		</form>
	);
}
