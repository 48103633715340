import { Button } from "components";

import { IcoMicrosoftBw } from "assets/icons";
import { UnloggedLayout } from "layouts";
import { AuthService } from "services/auth";
import "./styles.scss";

export default function Login() {
	const authService = new AuthService();

	const login = () => {
		authService.login();
	};

	return (
		<UnloggedLayout styles={{ "login-container": true }}>
			<span className="login-title">Login</span>
			<span className="login-text-body">
				Conecte com o seu email corporativo para acessar a plataforma
			</span>
			<Button
				kind="default"
				styled="secondary"
				cssClass="login-button"
				onClick={login}
			>
				<img
					className="loging-button-ico"
					src={IcoMicrosoftBw}
					alt="Ícone microsoft"
					height={24}
					width={24}
				/>
				<div className="login-button-divider" />
				<span>Continuar com Microsoft</span>
			</Button>
		</UnloggedLayout>
	);
}
