import { Covenant } from "models/covenant/Covenant";
import { CovenantCreationEditionPropsRequest } from "models/covenant/types";
import { ObjectWithAnyValues } from "utils/types";
import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class CovenantService extends BaseServices<Covenant> {
	constructor() {
		super(Covenant);
	}

	override async retrieve(id: string): Promise<Covenant> {
		return super.retrieve(`covenants/${id}`);
	}

	async listCovenantsPaginated(
		params: ObjectWithAnyValues
	): Promise<BasePageResponse> {
		return super.listPaginated("covenants", params);
	}

	async listCovenants(params: any): Promise<Covenant[]> {
		return this.get("covenants/list", params);
	}

	async settle(covenantId: string | number) {
		return this.patch(`covenants/${covenantId}/settle`, {});
	}

	async editCovenant(
		covenant: CovenantCreationEditionPropsRequest,
		covenantId: string
	) {
		return this.put(`covenants/${covenantId}`, covenant);
	}

	async importFromExcel(excelFile: File): Promise<Covenant> {
		const config = {
			headers: { "Content-Type": "multipart/form-data" }
		};

		const formData = new FormData();
		formData.append("file", excelFile);

		return this.post("covenants/upload", formData, config).then((data) =>
			Covenant.createFromResponse(data, this.model)
		);
	}

	exportCovenants(covenantIds: string[]) {
		const params = {
			params: { covenantsIds: covenantIds },
			paramsSerializer: { indexes: null },
			responseType: "blob"
		};
		return this.get("covenants/export", params);
	}
}
